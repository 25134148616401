<template>
  <div :class="clientBreakpoint.xs ? 'flex-wrap justify-center' : 'justify-space-between'" class="d-flex align-center margin-offset-1">
    <div :class="clientBreakpoint.xs ? 'justify-center mb-1' : 'justify-start'" class="d-flex flex-wrap justify-start align-center">
      <span class="font-weight-bold mx-1 keep-text-1-line">
        {{ i18n.t('generalFooter.alreadyCustomer') }}
      </span>
      <v-hover v-slot="{ hover }">
        <v-btn v-if=" component=='quote'" :style="{ 'background-color': hover ? '#153F70 !important' : '' }"
         class="service-bar-btn mx-1" outlined height="44px" @click="customerServiceClick()"><icon :class="hover ? 'white-svg':'blue-svg'" class="service-bar-span" svg='supportagent'></icon>
          <span :style="{ 'color': hover ? '#FFF !important' : '' }" class="service-bar-span pl-3">{{ i18n.t('header.title') }}</span>
        </v-btn>
        <v-btn v-else-if=" component== 'contact'" :style="{ 'background-color': hover ? '#153F70 !important' : '' }" class="service-bar-btn mt-1 ml-3" outlined height="44px" @click="requestQuoteClick()">
          <span :style="{ 'color': hover ? '#FFF !important' : '' }" class="service-bar-span">{{ i18n.t('requestQuote') }}</span>
        </v-btn>
      </v-hover>
    </div>
    <div class="d-flex align-center mb-1">
      <v-btn class="service-bar-btn-phone" height="44px" @click="showPhoneNumber()">
        <span :class="isPhoneNumberShowing?'pl-3':''" class="mt-1">
          <icon class="service-bar-span" svg='phone'></icon>
        </span>
        <a v-if="isPhoneNumberShowing" class="text-h6 white--text px-5" :href="`tel:${phoneNumber || phone}`">{{phoneNumber || phone}}</a>
      </v-btn>
    </div>
  </div>
</template>
<script>
import i18n from "@/i18n";
// import reset from '../../../plugins/reset'
import { useIndexStore } from '@/store/index';
import { mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import icon from "../utility/SvgLoader";
import { useUserStore } from '@/store/user';

export default {
  name: "CustomerServiceBar",
  components:{icon},
  props: {
    phone: String,
    service: String,
      },
  data() {
    return {
      i18n: i18n,
      widthSize: this.windowSize,
      phoneNumber: '',
    }
  },
  mounted: function () {
    this.getPNParameterInURL();
  },
  computed: {
    ...mapWritableState(useUserStore,[
      "form",
    ]),
    ...mapWritableState(useIndexStore, [
    "currentTabComponent",
    "component",
    "servicefromquote",
    "windowSize",
    "isPhoneNumberShowing",
    "clientBreakpoint"
    ])
  },

  methods: {
    ...mapActions(useIndexStore, ['onResize',"scrollToTop"]),

    showPhoneNumber() {
      this.isPhoneNumberShowing = !this.isPhoneNumberShowing;
    },
    updateView(component) {
      this.$emit("update", component);
    },
    getPNParameterInURL() {
      const urlParams = new URLSearchParams(window.parent.location.search);
      if (urlParams.has("pn")) {
        this.phoneNumber = urlParams.get("pn");
        this.isPhoneNumberShowing = true;
      }
    },
    customerServiceClick() {
      this.servicefromquote = 'quote';
      this.currentTabComponent = 'ContactWidgetV3';
      this.component = "contact";
      this.scrollToTop();
    },
    requestQuoteClick() {
      this.servicefromquote = '';
      this.currentTabComponent = this.form.menuOrder[this.form.menuOrder.length - 1];
      this.component = 'quote';
    }
  },
};
</script>
<style scoped>
  @media screen and (max-width: 875px){
  ::v-deep .keep-text-1-line{
    word-break: keep-all !important;
    vertical-align: text-top;
    }
  }
  @media screen and (max-width: 960px) {
    ::v-deep .service-bar-span{
      font-size:18px !important ;
    }
  }
  @media screen and (max-width: 600px) {
    ::v-deep .service-bar-span{
      font-size:18px !important ;
  }
  }@media screen and (max-width: 475px)  {

    ::v-deep .service-bar-span{
      font-size:16px !important ;
    }
  }
  @media screen and (max-width: 280px) {
    ::v-deep .service-bar-span{
      font-size:16px !important ;
    }
  }
  ::v-deep .service-bar-btn {
    text-transform: capitalize;
    border-radius: 7px !important;
    background-color: white !important;
    border-color: #003F6F !important;
    border-style: solid !important;
  }
  ::v-deep .service-bar-span {
    word-break: normal !important;
    font-size: 20px ;
    color: #003F6F !important;
  }
  ::v-deep .service-bar-btn-phone {
    background-color: #003F6F !important;
    border-radius: 7px;
    padding: 0px !important;
    min-width: 46px !important;
  }
  ::v-deep .white-svg {
    fill: white !important;
  }
  ::v-deep .blue-svg {
    fill: #1f528f !important;
  }
  .margin-offset-1 {
    margin-left: 7%;
    margin-right: 7%;
  }
</style>
