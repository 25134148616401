<template>
  <v-row>
    <v-col offset="1" cols="10">
      <v-form ref="wasteForm" v-model="formValid" lazy-validation>
        <div>
          <!-- <v-row>
            <v-col
              ><v-row>
                <v-card-title class="mx-auto mobile-my-20 text-title">
                  <icon svg="bulkPickup" max-width="52px" max-height="31px" class="green-svg mr-6"/>
                  Bulk Item Pickup
                </v-card-title>
              </v-row>
            </v-col>
          </v-row> -->
        <v-col class="pa-0 min-height-44 d-flex align-center">
          <BackBtn></BackBtn>
          <icon class="flex-grow-1" svg='step-marker-3'/>
        </v-col>
        <v-row>
          <v-col>
            <v-item-group>
              <v-item>
                  <v-card class="my-7 rounded-lg text-center" :class="form.serviceType == 'Residential' ? 'green-active-svg' : 'blue-active-svg'">
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-spacer v-if="$vuetify.breakpoint.xs ? false : true"></v-spacer>
                          <icon :svg="form.svgCategorySelected" class="white-svg" />
                          <v-list-item-content>
                            <v-list-item-title class="pl-5 card-label text-wrap white--text" >
                              {{ form.categoryType }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-spacer v-if="$vuetify.breakpoint.xs ? false : true"></v-spacer>
                          <icon svg="checkmark" />
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card>
              </v-item>
            </v-item-group>
          </v-col>
        </v-row>
        <!-- Display extra form if need more details Waste Recycling and Pickup -->
        <v-row>
          <v-col class="py-0">
            <v-card-text class="px-0 py-0 mb-1 text-label">
              {{i18n.t("tellUsWhat")}}
            </v-card-text>
            <v-textarea flat v-model="form.description" v-bind:disabled="loading"
            v-bind:rules="formRules.descriptionRules"
            height="90px" :placeholder="i18n.t('descriptionPlaceholder')" solo outlined no-resize
            class="rounded-xl-description input-label" color="grey"></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="form.serviceDetail == 'Other'">
          <v-col class="py-0">
            <v-textarea solo outlined flat dense class="rounded-lg" v-model="form.description"
            v-bind:disabled="loading" v-bind:rules="formRules.descriptionRules"
            :hint="i18n.t('descriptionHint')" counter="counter" height="30px" no-resize color="grey"></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0" :cols="$vuetify.breakpoint.xs ? 12 : 6">
            <v-card-text class="px-0 py-0 text-left mb-1 text-label">
              {{ i18n.t('startDate') }}
            </v-card-text>
            <v-menu v-model="form.wantedStartDateMenu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto" top attach="attach" v-bind:disabled="loading">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field readonly flat v-model="form.wantedStartDateFormatted" dense outlined :placeholder="i18n.t('select')" :hint="canada ? 'YYYY-MM-DD' : 'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind:rules="canada ? formRules.dateCanadaRules : formRules.dateRules" solo class="rounded-lg input-label" v-bind="attrs" v-on="on" v-bind:disabled="loading" color="grey"></v-text-field>
              </template>
              <v-date-picker v-model="form.wantedStartDate" no-title="no-title" v-bind:min="form.minDate" v-bind:max="form.maxDate" v-bind:locale="!canada ? 'en-US' : lang == 'fr' ? 'fr-CA' : 'en-CA'" v-on:input="form.wantedStartDateMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="!formValid || formErrors.length" class="my-3" >
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2" >
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField') }}
            </div>
          </div>
        </v-row>

        <div class="mt-6">
          <!-- <v-btn :width="$vuetify.breakpoint.mobile ? '39%': button.back.width" class="btn-label rounded-lg white--text mr-2 flex-grow-0 accent lighten-1" height="70px"  @click="loading ? null : back()">
            <v-icon x-large left>
              mdi-chevron-left
            </v-icon>
            <v-spacer></v-spacer>
            {{ i18n.t("back") }}
            <v-spacer></v-spacer>
          </v-btn>
          <v-btn :width="$vuetify.breakpoint.mobile ? '58%': button.continue.width" v-if="apiResult.storeFrontURL && getLeadTypeFromServiceType(form.serviceType, form.categoryType) == 'Residential'" :color="!formValid? 'error' : 'success'" outlined height="70px" class="btn-label rounded-lg " :class=" !formValid? 'red-outlined' : 'green-outlined'" :style="windowSize.x < 475 ? 'font-size: 14px !important' : ''" v-ripple="loadingWithService ? false : true" @click="loadingWithService ? null : validateWithService()">
            <v-spacer></v-spacer>
                    {{ loadingWithService ? i18n.t("validate") : i18n.t("goToStore") }}              <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
          <v-btn :width="$vuetify.breakpoint.mobile ? '58%': button.continue.width" v-else :color="!formValid? 'error' : 'success'" height="70px" class="btn-label rounded-lg" v-ripple="loading ? false : true" @click="loading ? null : validate()">
            <v-spacer></v-spacer>
                    {{ loading ? i18n.t("validate") : i18n.t("getMyQuote") }}
          <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn> -->
          <ForwardBtn :parentRefs="$refs" currentTabComponent="DetailView"></ForwardBtn>
        </div>
        <!-- <div v-if="apiResult.storeFrontURL && getLeadTypeFromServiceType(form.serviceType, form.categoryType) !== 'Residential'" class="d-flex flex-row mt-6 justify-end">
          <v-btn :width="$vuetify.breakpoint.mobile ? '58%': button.continue.width" :block="windowSize.x < 475 ? true : false" :color="!formValid? 'error' : 'success'" outlined height="70px" class="btn-label rounded-lg" :class=" !formValid? 'red-outlined' : 'green-outlined'" v-ripple="loadingWithService ? false : true" @click="loadingWithService ? null : sendInfoWithService()">
            <v-spacer></v-spacer>
              {{ loadingWithService ? i18n.t("validate") : i18n.t("goToStore") }}
            <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
        </div> -->
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import icon from "../../utility/SvgLoader";
import { useIndexStore } from '../../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import ForwardBtn from "../../utility/forwardBtn.vue"
import BackBtn from "../../utility/backBtn.vue"

export default {
  name: "EWaste",
  components:{icon,BackBtn,ForwardBtn},
  props: {
    canada: Boolean,
    terms: String,
    privacy: String,
    name: String,
    lang: String,
  },
  data() {
    return {
      i18n: i18n,
    };
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapState(useIndexStore, [
      "formRules",]),
    ...mapWritableState(useIndexStore, [
      "formErrors",
      "windowSize",
      "apiResult",
      "validationPages",
      'formValid',
      "loading",
      'loadingWithService',
      "button"
    ]),
    ...mapWritableState(useUserStore, [
      "form",
    ]),
  },
  watch: {
    "form.wantedStartDate": function () {
      if (this.form.wantedStartDate) {
        this.formatDate(this.form.wantedStartDate,this.canada ? "en_CA" : "","wantedStartDateFormatted");
      } else {
        this.form.wantedStartDateFormatted = null;
      }
    },
  },
  methods: {
    ...mapActions(useIndexStore, ['onResize','updateViewComponent','back','sendInfoWithService','getLeadTypeFromServiceType','updateStages','formatDate','acceptNumber']),
    validateWithService() {
      this.loadingWithService = true;
      var vm = this
      if (vm.$refs.wasteForm.validate()) {
        vm.form.webLeadStep = "Details Stage";
        vm.sendInfoWithService()
      }else{
        vm.loadingWithService = false;
      }
    },
    validate() {
      this.loading = true;
      var vm = this;
      if (vm.$refs.wasteForm.validate()) {
        vm.updateStages("Details Stage");
      } else {
        vm.loading = false;
      }
  },
  },
};
</script>

<style scoped>
  .min-height-44 {
    min-height: 44px;
  }
  .box-shadow-custom {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
  }
</style>