<template>
    <div>

    <!-- Google Address Field -->
        <GoogleAddress :reCaptcha="reCaptcha" :newstoreapi="newstoreapi" :lang="lang" :country="country" v-model="form.address" v-bind:rules="formRules.addressRules"/>

    <!-- First / Last Name FIELD -->
        <v-row>
        <!-- FIRST NAME -->
            <v-col class="my-0 py-0" :cols="clientBreakpoint.width < 650 ? 12 : 6">
                <v-card-text class="px-0 py-0 mb-1 text-label">
                    {{i18n.t("firstName")}}
                </v-card-text>
                <v-text-field flat dense name="first_name" :placeholder="i18n.t('firstNamePlaceholder')" v-bind:rules="formRules.firstNameRules" v-bind:disabled="loading" v-model="form.firstName" class="rounded-lg input-label" solo outlined prepend-inner-icon="mdi-account" color="grey"></v-text-field>
            </v-col>
        <!-- LAST NAME -->
            <v-col class="my-0 py-0" :cols="clientBreakpoint.width < 650 ? 12 : 6">
                <v-card-text class="px-0 py-0 mb-1 text-label">
                    {{ i18n.t("lastName") }}
                </v-card-text>
                <v-text-field flat dense class="rounded-lg input-label" name="last_name" :placeholder="i18n.t('lastNamePlaceholder')" v-bind:disabled="loading" v-model="form.lastName" v-bind:rules="formRules.lastNameRules" solo outlined prepend-inner-icon="mdi-account" color="grey"></v-text-field>
            </v-col>
        </v-row>

    <!-- Phone and Email Text Field -->
        <v-row>
        <!-- EMAIL -->
            <v-col class="my-0 py-0" :cols="clientBreakpoint.width < 650 ? 12 : 6">
                <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
                </v-slide-x-transition>
                <v-slide-x-transition hide-on-leave>
                    <v-card-text class="px-0 py-0 mb-1 text-label">
                        {{ i18n.t("email") }}
                    </v-card-text>
                </v-slide-x-transition>
                <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
                    <v-text-field flat v-model="form.email" solo dense outlined type="email" v-bind:rules="formRules.emailRules" name="email" :placeholder="i18n.t('emailPlaceholder')" v-bind:disabled="loading" class="input-label rounded-lg placeholder-position" prepend-inner-icon="mdi-email" color="grey"></v-text-field>
                </v-slide-x-transition>
            </v-col>
        <!-- PHONE -->
            <v-col class="my-0 py-0" :cols="clientBreakpoint.width < 650 ? 12 : 6">
                <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
                    <v-card-text class="px-0 py-0 mb-1 text-label">
                        {{ i18n.t("phone") }}
                    </v-card-text>
                </v-slide-x-transition>
                <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
                    <v-text-field flat v-model="form.phone" dense outlined class="input-label rounded-lg placeholder-position" solo @input="acceptNumber()" type="tel" v-bind:rules="formRules.phoneRules" v-bind:disabled="loading" name="Phone" :placeholder="i18n.t('phonePlaceholder')" prepend-inner-icon="mdi-phone" color="grey"></v-text-field>
                </v-slide-x-transition>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { useUserStore } from '@/store/user';
import { useIndexStore } from '@/store/index';

import { mapState, mapWritableState, mapActions } from 'pinia';
import GoogleAddress from './GoogleAddress.vue';
export default {
    name: 'UserDetailField',
    components:{GoogleAddress},
    props: {
        currentTabComponent: String,
        service: String,
        assigntodistrict: Number,
        lang: String,
        phone: String,
        country: String,
        terms: String,
        privacy: String,
        name: String,
        splitter: Boolean,
        load: Boolean,
        valid: Boolean,
        header: String,
        primary: String,
        secondary: String,
        storelayout: Boolean,
        parentRefs: Object,
        reCaptcha: Boolean,
        newstoreapi: Boolean,
    },
    data(){
        return {
            i18n: i18n
        }
    },
    computed: {
        ...mapState(useIndexStore,['formRules','component']),
        ...mapWritableState(useIndexStore,[
            'google',
            'completeAddress',
            'completeAddressMsg',
            'formValid',
            'loading',
            'prediction',
            'view',
            "dialog",
            'clientBreakpoint'
        ]),
        ...mapWritableState(useUserStore,[
            'form',
        ])
    },
    created() {
        i18n.locale = this.lang;
    },
    methods: {
        ...mapActions(useIndexStore,['acceptNumber']),
    },

}
</script>

<style scoped>
::v-deep .text-label {
  font-size: 16px;
  line-height: 21px;
  word-break: normal !important;
  color: black !important;
}
::v-deep .input-label {
  font-size: 14px !important;
  word-break: normal !important;
  color: black !important;
  border-radius: 10px !important;
}
::v-deep .placeholder-position input::placeholder {
  padding-left: 5px !important;
}
::v-deep .larger-checkbox .v-icon {
  /* Adjust the size of the v-icon checkbox here */
  font-size: 42px !important;
}
::v-deep .input-checkbox .v-input--selection-controls__input{
  background-color: white !important;
}
::v-deep .input-checkbox.checked .v-input--selection-controls__input{
  background-color: #1BAB42 !important;
}
::v-deep .v-input__icon.v-input__icon--prepend-inner i {
  font-size: 28px !important;
  color: #1f528f;
}
</style>