<template>
    <div :class="clientBreakpoint.width < 730 ? 'px-3 mx-3' : 'px-10 mx-10'">
        <v-row>
            <v-col>

            <!-- HEADER -->
                <v-row>
                    <v-col>
                        <v-card-text
                        primary-title
                        class="px-0 mb-0 title text-center font-weight-bold text-wrap">
                        {{i18n.t("fillYourPersonalData")}}
                        </v-card-text>
                        <BackView svg="step-marker-2-2" @click="dumpsterSelected = [];reset.address(true);updateViewComponent('ChooseRollOff')"/>
                    </v-col>
                </v-row>

            <!-- BODY -->
                <!-- ROLL OFF BANNER -->
                <div class="mb-10">
                    <v-card class="blue-selected-bg rounded-lg mb-3 px-6">
                        <v-row>
                            <!-- ICON ROLL OFF -->
                            <v-col cols="auto" :class="clientBreakpoint.xs ? 'pb-0' : ''">
                                <v-container fill-height text-center>
                                    <v-layout row wrap align-center>
                                        <v-flex>
                                            <icon svg="rolloff"></icon>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-col>
                            <!-- TITLE DETAIL OF ROLL OFF -->
                            <v-col cols="auto" class="pl-0" :class="clientBreakpoint.xs ? 'pb-0' : ''">
                                <v-container fill-height>
                                    <v-layout row wrap align-center>
                                        <v-flex>
                                            <h3 class="white--text">{{i18n.locale==='fr'?dumpsterSelected.size.textFr:dumpsterSelected.size.text}}</h3>
                                            <h4 class="white--text subtitle-2 font-weight-regular">{{dumpsterSelected.size.spec}}</h4>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-col>

                            <v-col :cols="clientBreakpoint.width < 730 ? 12 : ''">
                                <!-- DELIVERY DATE -->
                                <v-row>
                                    <v-col cols="12" class="py-0" >
                                        <v-menu v-model="form.wantedDeliveryDateMenu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto" bottom attach="attach" v-bind:disabled="loading && disabled">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="d-flex py-0">
                                                <v-card-text class="d-inline-flex align-center label-form white--text py-0 pl-2">{{i18n.t("deliveryDate")}}
                                                <v-text-field flat v-model="form.wantedDeliveryDateFormatted" dense outlined :placeholder="i18n.t('select')" :hint="canada ? 'YYYY-MM-DD' : 'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind:rules="canada ? formRules.dateCanadaRules : formRules.dateRules" solo class="white-v-message pl-3 d-flex align-center rounded-lg input-label pt-6" v-bind="attrs" v-on="on" v-bind:disabled="loading && disabled"></v-text-field>
                                                </v-card-text>
                                            </div>
                                        </template>
                                        <v-date-picker v-model="form.wantedDeliveryDate" no-title="no-title" v-bind:min="form.minDate" v-bind:max="form.maxDate" v-bind:locale="!canada ? 'en-US' : lang == 'fr' ? 'fr-CA' : 'en-CA'" v-on:input="form.wantedDeliveryDateMenu = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                <!-- PICKUP DATE -->

                                    <v-col cols="12" class="py-0" >
                                        <v-menu v-model="form.wantedEndDateMenu" :close-on-content-click="false" offset-y max-width="290px" min-width="auto" bottom attach="attach" v-bind:disabled="loading && disabled">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div class="d-flex py-0">
                                                <v-card-text class="d-inline-flex align-center label-form white--text py-0">{{i18n.t("pickupDate")}}
                                                <v-text-field flat v-model="form.wantedEndDateFormatted" dense outlined :placeholder="i18n.t('select')" :hint="canada ? 'YYYY-MM-DD' : 'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind:rules="canada ? formRules.dateCanadaRules : formRules.dateRules" solo class="white-v-message pl-3 d-flex align-center rounded-lg input-label pt-6" v-bind="attrs" v-on="on" v-bind:disabled="loading && disabled"></v-text-field>
                                                </v-card-text>
                                            </div>
                                        </template>
                                        <v-date-picker v-model="form.wantedEndDate" no-title="no-title" v-bind:min="form.minDate" v-bind:max="form.maxDate" v-bind:locale="!canada ? 'en-US' : lang == 'fr' ? 'fr-CA' : 'en-CA'" v-on:input="form.wantedEndDateMenu = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>

                <!-- FORM -->
                <v-form ref="form" v-model="formValid" lazy-validation>
                    <UserDetailField :lang="lang" :country="canada?'canada':''" :parentRefs="$refs" :newstoreapi="newstoreapi" :reCaptcha="true"></UserDetailField>
                    <DescriptionFieldText></DescriptionFieldText>
                    <TermsPolicy :name="name" :terms="terms" :privacy="privacy" :lang="lang"></TermsPolicy>
                </v-form>

            <!-- FOOTER -->
                <!-- ALERT MESSAGE -->
                <v-row v-if="!formValid" class="mt-6" >
                    <div class="v-messages error--text px-6 text-center" role="alert">
                        <div class="subtitle-2" >
                            <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField') }}
                        </div>
                    </div>
                </v-row>

                <!-- BUTTON -->
                <v-row>
                    <v-col>
                        <ButtonsView :loading="loading" :form="formValid"
                        @clickQuote="loading ? null : validate();component='rolloff'"
                        @clickStore="loading ? null : validateWithService()"
                        class="my-8"
                        :hideStore="!haveServicesForThisType()"></ButtonsView>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import i18n from '@/i18n';
import icon from "../utility/SvgLoader.vue"

import { mapState, mapWritableState,mapActions } from 'pinia';
import { useIndexStore } from '@/store/index';
import { useUserStore } from '@/store/user';
import { useRollOffStore } from '@/store/rolloffdumpster';
import { useDumpsterCommercialStore } from '@/store/dumpstercommercial';

import ButtonsView from '@/components/sfc/ButtonsView.vue';
import BackView from '@/components/sfc/BackView.vue';
import UserDetailField from '@/components/sfc/UserDetailField'
import DescriptionFieldText from '@/components/sfc/DescriptionFieldText'

import TermsPolicy from'@/components/sfc/TermsPolicy'
import storefront from '@/plugins/storefront'
import reset from '@/plugins/reset'


export default {
    name:"RollOffForm",
    components:{
        icon,
        ButtonsView,
        BackView,
        UserDetailField,
        DescriptionFieldText,
        TermsPolicy,
    },
    props:{
        service: String,
        assigntodistrict: Number,
        lang: String,
        phone: String,
        country: String,
        terms: String,
        privacy: String,
        name: String,
        splitter: Boolean,
        load: Boolean,
        valid: Boolean,
        canada: Boolean,

        header: String,
        primary: String,
        secondary: String,

        serviceswantedwithpricing: Boolean,
        newstoreapi: Boolean,

    },
    data() {
        return{
            i18n:i18n,
            disabled: true,
            reset: reset,
        }
    },
    watch:{

        "apiResult.data": function(newVal,oldVal){
            if(newVal !== oldVal){
                if(this.apiResult.data){

                    this.disableRollOffList();

                }
            }
        },

        "form.address": function(newVal,oldVal){
            if(newVal !== oldVal){
                reset.apiStoreData();
                this.form.address = newVal;
                this.dumpsterSelected.size.available = false;
                this.addressVerified = false;

            }
        },

        // Delivery Date
        "form.wantedDeliveryDate": function () {
            if (this.form.wantedDeliveryDate) {

                this.formatDate(this.form.wantedDeliveryDate,this.canada ? "en_CA" : "","wantedDeliveryDateFormatted");

            } else {

                this.form.wantedDeliveryDateFormatted = null;

            }
        },

        // Pickup date
        "form.wantedEndDate": function () {
            if (this.form.wantedEndDate) {

                this.formatDate(this.form.wantedEndDate,this.canada ? "en_CA" : "","wantedEndDateFormatted");

            } else {

                this.form.wantedEndDateFormatted = null;

            }
        },
    },

    computed:{
        ...mapState(useIndexStore,[
            'formRules'
        ]),
        ...mapWritableState(useIndexStore,[
            'loading','apiResult','addressVerified','clientBreakpoint'
        ]),
        ...mapWritableState(useUserStore,['form']),
        ...mapWritableState(useRollOffStore,[
            'items','rollOffList','materialDisposeList','formValid'
        ]),
        ...mapWritableState(useDumpsterCommercialStore, ["dumpsterSelected"]),

    },
    methods:{
        ...mapActions(useIndexStore,['updateViewComponent','acceptNumber','sendHttpRequestQuote','haveServicesForThisType','updateStages','formatDate','updatedServicesWantedForm']),
        ...mapActions(useRollOffStore,['deleteRollOff','disableRollOffList','sendHttpRequestRollOff']),
        validateWithService() {
            this.loading = true;
            storefront.sendInfoWithService();

        },
        validate() {
            this.loading = true;
            var vm = this;
            if (vm.$refs.form.validate()) {

                vm.updatedServicesWantedForm();
                vm.sendHttpRequestRollOff();

            } else {
                vm.loading = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .white-v-message .v-messages__message{
    color: white;
}
</style>