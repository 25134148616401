import salesforce from "@/plugins/salesforce"
import googleApi from "@/plugins/google"
import reset from "@/plugins/reset"
import storefront from "@/plugins/storefront";
import axios from "axios";
import { load } from "recaptcha-v3";
import i18n from "@/i18n";
import { defineStore } from 'pinia'
import cloneDeep from 'lodash.clonedeep';
import { useDumpsterCommercialStore } from "./dumpstercommercial";
import { useUserStore } from "./user";
import { useAxiosFormStore } from "./axios";
import { useContactUsStore } from "./contactus";

export const useIndexStore = defineStore('IndexStore', {
  state: () => (
      {
        addressVerified: false,
        // Store tracking
        utmSource: '',
        utmCampaign: '',
        utmMedium: '',
        utmTerm: '',
        utmReferrer: '',
        gclid: '',
        fclid: '',
        promoCode: '',
        // quote
        form: useUserStore().form,
        component: '', // 'quote' || contact
        currentTabComponent: "", // 'ServiceView' ||
        servicefromquote: '',
        google: null,
        serviceFormErrors: false,
        formErrors: [],
        snackbar: false,
        completeAddress: false,
        dialog: false,
        recaptchaDialog: false,
        completeAddressMsg: "",
        trackViewSplitter: false,
        errorAxios: false,
        // View for when processing orders
        orderView: '',
        apiName: '',
        button:{
          back: {
            width: '19%'
          },
          continue: {
            width: '80%'
          }
        },
        view: {
          prediction: false,
          hoa: false,
          tenant: false,
          hoaAndTenant: false,
        },
        loading: false,
        loadingWithService:false,
        formValid: true,
        apartmentsCondosHoas: false,
        // FOOTER
        isPhoneNumberShowing: false,
        storefrontDistrictCodeOverride: 0,
        servicesofferedResi: "",
        servicesofferedComm: "",
        newStoreApi: false,
        headerstore: false,
        serviceLobLocked: false,
        resiServicesSizes: "",
        commServicesSizes: "",
        constServicesSizes: "",
        resiServicesFrequency: "",
        commServicesFrequency: "",
        resiServicesShowRecycling: false,
        commServicesShowRecycling: false,
        windowSize: {
          x: 0,
          y: 0,
        },
        clientBreakpoint: {
          width: 0,
          height: 0,
          xs: null,
          sm: null,
          md: null,
          lg: null,
          xl: null,

        },
        commChartOfficeLabel: true,
        apiResultOriginal: {
          data: null,
          storeFrontURL: "",
          districtID: "",
        },
        apiResult: {
          data: null,
          storeFrontURL: "",
          districtID: "",
        },
        storefrontResult: {
          store: null,
          user: null,
        },
        // List text field
        jsonForm:[
          {
            id:0,
            icon: '',
            title: '',
            qty: 0,
            size: {} ,
            frequency: {},
          }
        ],
        servicesType: [
          {
            id: 0,
            name: "ResidentialService",
            text: "serviceView.home",
            leadType:"Residential",
            icon: "residential",
            color: "#175E45",
            checked: false,
          },
          {
            id: 1,
            name: "CommercialService",
            text: "serviceView.business",
            leadType:"Commercial",
            icon: "business",
            color: "#1F528F",
            checked: false,
          },
        ],
        sizes: [
          {size: i18n.t('sizes.9596GallonCart', 'en'), sizeFr: i18n.t('sizes.9596GallonCart', 'fr')},
          {size: i18n.t('sizes.2Yard', 'en'), sizeFr: i18n.t('sizes.2Yard', 'fr')},
          {size: i18n.t('sizes.3Yard', 'en'), sizeFr: i18n.t('sizes.3Yard', 'fr')},
          {size: i18n.t('sizes.4Yard', 'en'), sizeFr: i18n.t('sizes.4Yard', 'fr')},
          {size: i18n.t('sizes.6Yard', 'en'), sizeFr: i18n.t('sizes.6Yard', 'fr')},
          {size: i18n.t('sizes.8Yard', 'en'), sizeFr: i18n.t('sizes.8Yard', 'fr')},
          {size: i18n.t('sizes.10Yard', 'en'), sizeFr: i18n.t('sizes.10Yard', 'fr')},
          ],
        servicesWantedForm:[
          {
            id:0,
            title: i18n.t('servicesWantedForm.solidWaste', 'en'),
            titleFr: i18n.t('servicesWantedForm.solidWaste', 'fr'),
            qty: 0,
            size: {} , //
            frequency: {}, //
            icon: 'solidwaste'
          },
          {
            id:1,
            title: i18n.t('servicesWantedForm.recyclingDumpster', 'en'),
            titleFr: i18n.t('servicesWantedForm.recyclingDumpster', 'fr'),
            size: {} , //
            frequency: {}, //
            qty: 0,
            icon: 'recyclingdumpstericon'
          },
        ],
        residentialWantedForm:[
          {
            id:0,
            title: i18n.t('residentialWantedForm.garbagePickup', 'en'),
            titleFr: i18n.t('residentialWantedForm.garbagePickup', 'fr'),
            qty: 0,
            size: {} , //
            frequency: {}, //
            icon: 'home-garbage'
          },
          {
            id:1,
            title: i18n.t('residentialWantedForm.recycling', 'en'),
            titleFr: i18n.t('residentialWantedForm.recycling', 'fr'),
            size: {} , //
            frequency: {}, //
            qty: 0,
            icon: 'home-garbage-recycling',
          },
        ],
        residentialMhoamWantedForm:[
          {
            id:0,
            title: i18n.t('residentialWantedForm.garbagePickup', 'en'),
            titleFr: i18n.t('residentialWantedForm.garbagePickup', 'fr'),
            qty: 0,
            size: {} , //
            frequency: {}, //
            icon: 'solidwaste',
            icon2: 'mhoam-home-garbage'
          },
          {
            id:1,
            title: i18n.t('residentialWantedForm.recycling', 'en'),
            titleFr: i18n.t('residentialWantedForm.recycling', 'fr'),
            size: {} , //
            frequency: {}, //
            qty: 0,
            icon: 'recyclingdumpstericon',
            icon2: 'mhoam-home-garbage-recycling'
          },
        ],
        commercialWantedForm:[
          {
            id:0,
            title: i18n.t('servicesWantedForm.solidWaste', 'en'),
            titleFr: i18n.t('servicesWantedForm.solidWaste', 'fr'),
            qty: 0,
            size: {} , //
            frequency: {}, //
            icon: 'solidwaste',
          },
          {
            id:1,
            title: i18n.t('servicesWantedForm.recyclingDumpster', 'en'),
            titleFr: i18n.t('servicesWantedForm.recyclingDumpster', 'fr'),
            size: {} , //
            frequency: {}, //
            qty: 0,
            icon: 'recyclingdumpstericon',
          },
        ],
        commercialMhoamWantedForm:[
          {
            id:0,
            title: i18n.t('residentialWantedForm.garbagePickup', 'en'),
            titleFr: i18n.t('residentialWantedForm.garbagePickup', 'fr'),
            qty: 0,
            size: {} , //
            frequency: {}, //
            icon: 'solidwaste',
            icon2: 'mhoam-home-garbage'
          },
          {
            id:1,
            title: i18n.t('residentialWantedForm.recycling', 'en'),
            titleFr: i18n.t('residentialWantedForm.recycling', 'fr'),
            size: {} , //
            frequency: {}, //
            qty: 0,
            icon: 'recyclingdumpstericon',
            icon2: 'mhoam-home-garbage'
          },
        ],
        sizesResiWasteRecycling: [
            {
            id:0,
            text: i18n.t('iAmNotSure', 'en'),
            textFr: i18n.t('iAmNotSure', 'fr'),
            binSize: "",
            amountGarbages: 0,
            weight:'',
            spec: '',
            // businessType:'',
            // description:'',
            icon:'',
            byDefault: true,
          },
          {
            id:1,
            text: i18n.t('sizesResiWasteRecycling.standard90', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.standard90', 'fr'),
            binSize: "90 GALLON",
            amountGarbages: 7,
            weight:'7 bags',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
          },
          {
            id:2,
            text: i18n.t('sizesResiWasteRecycling.standard95', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.standard95', 'fr'),
            binSize: "95 GALLON",
            amountGarbages: 7,
            weight:'7 bags',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
          },
          {
            id:3,
            text: i18n.t('sizesResiWasteRecycling.standard', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.standard', 'fr'),
            binSize: "96 GALLON",
            amountGarbages: 7,
            weight:'7 bags',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
            byDefault: true,
          },
          {
            id:4,
            text: i18n.t('sizesResiWasteRecycling.extraSmall', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.extraSmall', 'fr'),
            binSize: "18 GALLON",
            amountGarbages: 1,
            weight:'1 bag',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
            byDefault: true,
          },
          {
            id:5,
            text: i18n.t('sizesResiWasteRecycling.small', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.small', 'fr'),
            binSize: "32 GALLON",
            amountGarbages: 2,
            weight:'2 bag',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
            byDefault: true,
          },
          {
            id:6,
            text: i18n.t('sizesResiWasteRecycling.small35', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.small35', 'fr'),
            binSize: "35 GALLON",
            amountGarbages: 2,
            weight:'2 bag',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
          },
          {
            id:7,
            text: i18n.t('sizesResiWasteRecycling.medium60', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.medium60', 'fr'),
            binSize: "60 GALLON",
            amountGarbages: 4,
            weight:'4 bag',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
          },
          {
            id:8,
            text: i18n.t('sizesResiWasteRecycling.medium', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.medium', 'fr'),
            binSize: "64 GALLON",
            amountGarbages: 4,
            weight:'4 bag',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
            byDefault: true,
          },
          {
            id:9,
            text: i18n.t('sizesResiWasteRecycling.medium65', 'en'),
            textFr: i18n.t('sizesResiWasteRecycling.medium65', 'fr'),
            binSize: "65 GALLON",
            amountGarbages: 4,
            weight:'4 bag',
            spec: '',
            // businessType:'Small retails shops & Restaurants',
            // description:'25 - 50 Office Staff',
            icon:'home-garbage',
          },
        ],
        sizesWasteRecycling: [
          {
          id:0,
          text: i18n.t('iAmNotSure', 'en'),
          textFr: i18n.t('iAmNotSure', 'fr'),
          binSize: "",
          binSizeFr: "",
          amountGarbages: 0,
          weight:'',
          weightFr:'',
          spec: '',
          // businessType:'',
          // description:'',
          icon:'',
          byDefault: true,
        },
        {
          id:1,
          text: i18n.t('sizesWasteRecycling.60Gallon', 'en'),
          textFr: i18n.t('sizesWasteRecycling.60Gallon', 'fr'),
          binSize: i18n.t('sizes.60Gallon', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.60Gallon', 'fr').toUpperCase(),
          amountGarbages: 5,
          weight:'5 ' + i18n.t('bags', 'en'),
          weightFr:'5 ' + i18n.t('bags', 'fr'),
          spec: '',
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'home-garbage',
        },
        {
          id:2,
          text: i18n.t('sizesWasteRecycling.65Gallon', 'en'),
          textFr: i18n.t('sizesWasteRecycling.65Gallon', 'fr'),
          binSize: i18n.t('sizes.65Gallon', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.65Gallon', 'fr').toUpperCase(),
          amountGarbages: 5,
          weight:'5 ' + i18n.t('bags', 'en'),
          weightFr:'5 ' + i18n.t('bags', 'fr'),
          spec: '',
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'home-garbage',
        },
        {
          id:3,
          text: i18n.t('sizesWasteRecycling.90Gallon', 'en'),
          textFr: i18n.t('sizesWasteRecycling.90Gallon', 'fr'),
          binSize: i18n.t('sizes.90Gallon', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.90Gallon', 'fr').toUpperCase(),
          amountGarbages: 7,
          weight:'7 ' + i18n.t('bags', 'en'),
          weightFr:'7 ' + i18n.t('bags', 'fr'),
          spec: '',
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'home-garbage',
        },
        {
          id:4,
          text: i18n.t('sizesWasteRecycling.96Gallon', 'en'),
          textFr: i18n.t('sizesWasteRecycling.96Gallon', 'fr'),
          binSize: i18n.t('sizes.96Gallon', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.96Gallon', 'fr').toUpperCase(),
          amountGarbages: 7,
          weight:'7 ' + i18n.t('bags', 'en'),
          weightFr:'7 ' + i18n.t('bags', 'fr'),
          spec: '',
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'home-garbage',
          byDefault: true,
        },
        {
          id:5,
          text: i18n.t('sizesWasteRecycling.300Gallon', 'en'),
          textFr: i18n.t('sizesWasteRecycling.300Gallon', 'fr'),
          binSize: i18n.t('sizes.300Gallon', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.300Gallon', 'fr').toUpperCase(),
          amountGarbages: 23,
          weight:'23 ' + i18n.t('bags', 'en'),
          weightFr:'23 ' + i18n.t('bags', 'fr'),
          spec: '',
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'home-garbage',
        },
        {
          id:6,
          text: i18n.t('sizesWasteRecycling.1Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.1Yard', 'fr'),
          binSize: i18n.t('sizes.1Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.1Yard', 'fr').toUpperCase(),
          amountGarbages:12,
          weight:'200 ' + i18n.t('lbs', 'en'),
          weightFr:'200 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.1YardDumpsterSpec', 'en'),
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'commercial-dumpster',
        },
        {
          id:7,
          text: i18n.t('sizesWasteRecycling.1dot5Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.1dot5Yard', 'fr'),
          binSize: i18n.t('sizes.1dot5Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.1dot5Yard', 'fr').toUpperCase(),
          amountGarbages:18,
          weight:'300 ' + i18n.t('lbs', 'en'),
          weightFr:'300 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.1dot5YardDumpsterSpec', 'en'),
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'commercial-dumpster',
        },
        {
          id:8,
          text: i18n.t('sizesWasteRecycling.2Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.2Yard', 'fr'),
          binSize: i18n.t('sizes.2Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.2Yard', 'fr').toUpperCase(),
          amountGarbages:24,
          weight:'400 ' + i18n.t('lbs', 'en'),
          weightFr:'400 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.2YardDumpsterSpec', 'en'),
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'commercial-dumpster',
          byDefault: true,
        },
        {
          id:9,
          text: i18n.t('sizesWasteRecycling.3Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.3Yard', 'fr'),
          binSize: i18n.t('sizes.3Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.3Yard', 'fr').toUpperCase(),
          amountGarbages:30,
          weight:'600 ' + i18n.t('lbs', 'en'),
          weightFr:'600 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.2YardDumpsterSpec', 'en'),
          // businessType:'Small retails shops & Restaurants',
          // description:'25 - 50 Office Staff',
          icon:'commercial-dumpster',
          byDefault: true,
        },
        {
          id:10,
          text: i18n.t('sizesWasteRecycling.4Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.4Yard', 'fr'),
          binSize: i18n.t('sizes.4Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.4Yard', 'fr').toUpperCase(),
          amountGarbages:36,
          weight:'800 ' + i18n.t('lbs', 'en'),
          weightFr:'800 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.4YardDumpsterSpec', 'en'),
          // businessType:'Small retails shops & Restaurants',
          // description:'50 Office Staff',
          icon:'commercial-dumpster',
          byDefault: true,
        },
        {
          id:11,
          text: i18n.t('sizesWasteRecycling.6Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.6Yard', 'fr'),
          binSize: i18n.t('sizes.6Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.6Yard', 'fr').toUpperCase(),
          amountGarbages:48,
          weight:'1,200 ' + i18n.t('lbs', 'en'),
          weightFr:'1 200 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.4YardDumpsterSpec', 'en'),
          // businessType:'Midsize retails shops & Restaurants',
          // description:'100 Office Staff',
          icon:'commercial-dumpster',
          byDefault: true,
        },
        {
          id:12,
          text: i18n.t('sizesWasteRecycling.8Yard', 'en'),
          textFr: i18n.t('sizesWasteRecycling.8Yard', 'fr'),
          binSize: i18n.t('sizes.8Yard', 'en').toUpperCase(),
          binSizeFr: i18n.t('sizes.8Yard', 'fr').toUpperCase(),
          amountGarbages:72,
          weight:'1,800 ' + i18n.t('lbs', 'en'),
          weightFr:'1 800 ' + i18n.t('lbs', 'fr'),
          spec: i18n.t('dumpsters.6YardDumpsterSpec', 'en'),
          // businessType:'Big retails shops & Restaurants',
          // desc:'250 Office Staff',
          icon:'commercial-dumpster',
          byDefault: true,
        },
      ],
      sizesROWasteRecycling: [
        {id: 0, text: i18n.t('10Yard', 'en'), textFr: i18n.t('10Yard', 'fr'), binSize: '10 YARD', description: i18n.t('heavyLoadsRockDirt', 'en'), byDefault: true,},
        {id: 1, text: i18n.t('12Yard', 'en'), textFr: i18n.t('12Yard', 'fr'), binSize: '12 YARD', description: i18n.t('heavyLoadsRockDirt', 'en'), byDefault: true,},
        {id: 2, text: i18n.t('15Yard', 'en'), textFr: i18n.t('15Yard', 'fr'), binSize: '15 YARD',description: i18n.t('homeCleanOutsFitsInMostDriveways', 'en'), byDefault: true,},
        {id: 3, text: i18n.t('20Yard', 'en'), textFr: i18n.t('20Yard', 'fr'), binSize: '20 YARD', description: i18n.t('remodelingLandscaping', 'en'), byDefault: true,},
        {id: 4, text: i18n.t('30Yard', 'en'), textFr: i18n.t('30Yard', 'fr'), binSize: '30 YARD', description: i18n.t('remodelingAndConstruction', 'en'), byDefault: true,},
        {id: 5, text: i18n.t('40Yard', 'en'), textFr: i18n.t('40Yard', 'fr'), binSize: '40 YARD', description: i18n.t('largeAmountOfConstructionDebris', 'en'), byDefault: true,},
      ],
      pickupFrequencies: [
        { id: 0, frequency: i18n.t('frequencyPicker.1xPerWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.1xPerWeek', 'fr'), pickupFrequency:"1XW"},
        { id: 1, frequency: i18n.t('frequencyPicker.2xPerWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.2xPerWeek', 'fr'), pickupFrequency:"2XW"},
        { id: 2, frequency: i18n.t('frequencyPicker.3xPerWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.3xPerWeek', 'fr'), pickupFrequency:"3XW"},
        { id: 3, frequency: i18n.t('frequencyPicker.everyOtherWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.everyOtherWeek', 'fr'), pickupFrequency:"EOW"},
        { id: 4, frequency: i18n.t('frequencyPicker.monthly', 'en'), frequencyFr: i18n.t('frequencyPicker.monthly', 'fr'), pickupFrequency:"1XM"},
        { id: 5, frequency: i18n.t('frequencyPicker.other', 'en'), frequencyFr: i18n.t('frequencyPicker.other', 'fr'), pickupFrequency:"Other"}
      ],
      itemsType: [
        {itemType: i18n.t('itemsType.furniture', 'en'), itemTypeFr: i18n.t('itemsType.furniture', 'fr')},
        {itemType: i18n.t('itemsType.appliance', 'en'), itemTypeFr: i18n.t('itemsType.appliance', 'fr')},
        {itemType: i18n.t('itemsType.mattress', 'en'), itemTypeFr: i18n.t('itemsType.mattress', 'fr')},
        {itemType: i18n.t('itemsType.other', 'en'), itemTypeFr: i18n.t('itemsType.other', 'fr')},
        ],
      businessTypes: [
        { id: 1, name: i18n.t('businessTypes.restaurantsHospitality', 'en'), nameFr: i18n.t('businessTypes.restaurantsHospitality', 'fr') },
        { id: 2, name: i18n.t('businessTypes.healthcareProfessionalServices', 'en'), nameFr: i18n.t('businessTypes.healthcareProfessionalServices', 'fr') },
        { id: 3, name: i18n.t('businessTypes.retail', 'en'), nameFr: i18n.t('businessTypes.retail', 'fr') },
        { id: 4, name: i18n.t('businessTypes.constructionDemolition', 'en'), nameFr: i18n.t('businessTypes.constructionDemolition', 'fr') },
        { id: 5, name: i18n.t('businessTypes.transportationWarehousing', 'en'), nameFr: i18n.t('businessTypes.transportationWarehousing', 'fr') },
        { id: 6, name: i18n.t('businessTypes.officesBuildingManagement', 'en'), nameFr: i18n.t('businessTypes.officesBuildingManagement', 'fr') },
        { id: 7, name: i18n.t('businessTypes.manufacturingIndustrial', 'en'), nameFr: i18n.t('businessTypes.manufacturingIndustrial', 'fr') },
        { id: 8, name: i18n.t('businessTypes.personalServices', 'en'), nameFr: i18n.t('businessTypes.personalServices', 'fr') },
        { id: 9, name: i18n.t('businessTypes.realEstateRentalLeasing', 'en'), nameFr: i18n.t('businessTypes.realEstateRentalLeasing', 'fr') },
        { id: 10, name: i18n.t('businessTypes.other', 'en'), nameFr: i18n.t('businessTypes.other', 'fr') },
      ],
      serviceDetails: [
        {serviceDetail: i18n.t('temporaryDisposalService', 'en'), serviceDetailFr: i18n.t('temporaryDisposalService', 'fr')},
        {serviceDetail: i18n.t('portableToilet', 'en'), serviceDetailFr: i18n.t('portableToilet', 'fr')},
        {serviceDetail: i18n.t('recyclingService', 'en'), serviceDetailFr: i18n.t('recyclingService', 'fr')},
        {serviceDetail: i18n.t('regularWasteDisposoalService', 'en'), serviceDetailFr: i18n.t('regularWasteDisposoalService', 'fr')},
        {serviceDetail: i18n.t('rollOffContainerNeeded', 'en'), serviceDetailFr: i18n.t('rollOffContainerNeeded', 'fr')},
        {serviceDetail: i18n.t('serviceDetailOther', 'en'), serviceDetailFr: i18n.t('serviceDetailOther', 'fr')},
        ],
        summaryList: {},
      }
    ),
  getters: {
    formRules() {
      return {
        acceptConditions: [(v) => !!v || i18n.t("mustAgree")],
        serviceTypeRules: [(v) => !!v || i18n.t("serviceTypeRequired")],
        firstNameRules: [(v) => !!v || i18n.t("firstNameRequired"), (v) => (v && v.length >= 2 && v.length <= 20) || i18n.t("firstNameLength"), (v) =>/^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(v) || i18n.t("firstNameValid"),],
        lastNameRules: [(v) => !!v || i18n.t("lastNameRequired"), (v) => (v && v.length >= 2 && v.length <= 20) || i18n.t("lastNameLength"), (v) => /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(v) || i18n.t("lastNameValid"),],
        businessNameRules: [(v) => !!v || i18n.t("businessNameRequired"), (v) => ((v.length == 0 || v.length >= 2) && v.length <= 60),],
        addressRules: [(v) => !!v || i18n.t("addressRequired"), (v) => (!!v && v.length >= 2 && v.length <= 300) || i18n.t("addressLength"),],
        phoneRules: [(v) => !!v || i18n.t("phoneRequired"), (v) => (v && v.length >= 2 && v.length <= 100) || i18n.t("phoneLength"), (v) => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || i18n.t("phoneValid"),],
        phoneOptionalRules: [(v) => v == "" || (v && v.length >= 2 && v.length <= 100) || i18n.t("phoneLength"), (v) => v == "" || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || i18n.t("phoneValid"),],
        emailRules: [(v) => !!v || i18n.t("emailRequired"), (v) => /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(v) || i18n.t("emailValid"),],

        // **** New rules added ****
        serviceDetailRules: [(v) => !!v || i18n.t("serviceDetailRequired")],
        shreddingDetailRules: [(v) => !!v || i18n.t("shreddingDetailRequired")],
        descriptionRules: [(v) => (v || "").length <= 600 || i18n.t("descriptionLength")],

        // cartSizeRules: [(v) => !!v || i18n.t("cartSizeRequired")],
        // cartQuantityRules: [(v) => v.value <= 1 <= 10 || i18n.t("cartQuantityRequired")],
        // pickupFrequencyRules: [(v) => !!v || i18n.t("pickupFrequencyRequired")],
        // businessTypeRules: [(v) => !!v || i18n.t("businessTypeRequired")],

        // startDateRules: [(v) => !!v || i18n.t("startDateRequired")],
        // pickupDateRules: [(v) => !!v || i18n.t("pickupDateRequired")],
        // deliveryDateRules: [(v) => !!v || i18n.t("deliveryDateRequired")],

        //dateRules: [(v) => !!v || this.$i18n.t("dateRequired"), (v) => new Date(v) >= new Date( new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate() ) || this.$i18n.t("formRules.date.invalid"), (v) => new Date(v) <= new Date( new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() ) || this.$i18n.t("formRules.date.invalid"), (v) => /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(v) || this.$i18n.t("formRules.date.invalid"), ],
        //dateCanadaRules: [ (v) => !!v || this.$i18n.t("dateRequired"), (v) => new Date( new Date(v).getFullYear(), new Date(v).getMonth(), new Date().getDate() ) >= new Date( new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate() ) || this.$i18n.t("formRules.date.invalid"), (v) => new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date().getDate() ) <= new Date( new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() ) || this.$i18n.t("formRules.date.invalid"), (v) => /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(v) || this.$i18n.t("formRules.date.invalid"),],
        //itemTypeRules: [(v) => !!v || i18n.t("itemTypeRequired")],
        quantityRules: [(v) => (v >= 0 || v >= 7) || i18n.t("quantityRequired")],
        //optionalQuantityRules: [(v) => (v >= 0 || v >= 7) || i18n.t("quantityRequired")],
      };
    },
    hoas() {
      if (!this.form.hoa) {
        return null;
      } else {
        return Object.keys(this.form.hoa)
          .map((key) => {
            return {
              key,
              value: this.form.hoa[key] || "",
            };
          })
          .filter((item) => item.key === "message");
      }
    },
    isConstruction() {
      const leadType = this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType);
      // (leadType==='Construction' || leadType==='Commercial')&&!this.form.menuNavigationId.includes('Storefront');
      return leadType==='Construction' || leadType==='Commercial' || leadType==='Portable Toilet';
    },
    showGetAQuote() {
        if (this.currentTabComponent !== 'ServiceView' && this.currentTabComponent !== 'AddressView' && this.currentTabComponent !== 'DetailView') {
            if (!this.form.menuNavigationId) {
                return true;
            }
            if (this.form.menuNavigationId && !this.haveServicesForThisType()) {
                return true;
            }
            if (this.form.menuNavigationId && this.haveServicesForThisType() && this.isConstruction) {
                return true;
            }
        }
        return false;
    },
    showGoToStore() {
        return this.form.menuNavigationId && this.haveServicesForThisType() && !['ServiceView', 'AddressView', 'DetailView'].includes(this.currentTabComponent);
    },
  },
  actions: {
    //  ***********************************************
    //  GENERAL FUNCTIONS FOR ANY PROJECT
    //  ***********************************************
    // Update view of current component
    updateViewComponent(component, skipScroll) {

      this.currentTabComponent = component;
      if(!skipScroll){
        this.scrollToTop();
      }

    },

    // Auto format for phone number. Adds parentheses and dashes
    acceptNumber() {

      var x = this.form.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ")" + x[2] + (x[3] ? "-" + x[3] : "");

    },

    // remove special characters and white space
    formatPhone(phoneNumber){

      var str = phoneNumber.replace(/[^a-zA-Z0-9 ]/g, "");
      return str;

    },

    // Scroll to widget
    scrollToTop() {
      document.getElementById('scrollToEmbed').scrollIntoView({behavior: "instant"});
    },

    // Format date for request quote widget
    formatDate(date, iso, fieldDate) {
      if (fieldDate == "wantedDeliveryDateFormatted") {
        if (iso === "en_CA") {
          this.form.wantedDeliveryDateFormatted =
            date.substring(0, 4) +
            "-" +
            date.substring(5, 7) +
            "-" +
            date.substring(8, 10);
        } else {
          this.form.wantedDeliveryDateFormatted =
            date.substring(5, 7) +
            "/" +
            date.substring(8, 10) +
            "/" +
            date.substring(0, 4);
        }
      }
      if (fieldDate == "wantedEndDateFormatted") {
        if (iso === "en_CA") {
          this.form.wantedEndDateFormatted =
            date.substring(0, 4) +
            "-" +
            date.substring(5, 7) +
            "-" +
            date.substring(8, 10);
        } else {
          this.form.wantedEndDateFormatted =
            date.substring(5, 7) +
            "/" +
            date.substring(8, 10) +
            "/" +
            date.substring(0, 4);
        }
      }
      if (fieldDate == "wantedStartDateFormatted") {
        if (iso === "en_CA") {
          this.form.wantedStartDateFormatted =
            date.substring(0, 4) +
            "-" +
            date.substring(5, 7) +
            "-" +
            date.substring(8, 10);
        } else {
          this.form.wantedStartDateFormatted =
            date.substring(5, 7) +
            "/" +
            date.substring(8, 10) +
            "/" +
            date.substring(0, 4);
        }
      }
      if(!fieldDate){
        if (iso === "en_CA") {
          return (
            date.substring(0, 4) +
            "-" +
            date.substring(5, 7) +
            "-" +
            date.substring(8, 10)
          );
        } else {
          return (
            date.substring(5, 7) +
            "/" +
            date.substring(8, 10) +
            "/" +
            date.substring(0, 4)
          );
        }
      }
    },

    // Get the size of the EMBED, not the window completely
    embedResize (){
      this.clientBreakpoint = {
        width: document.getElementById('scrollToEmbed').clientWidth,

        height: document.getElementById('scrollToEmbed').clientHeight,

        xs: document.getElementById('scrollToEmbed').clientWidth < 600 ? true : false,

        sm: document.getElementById('scrollToEmbed').clientWidth > 600 && document.getElementById('scrollToEmbed').clientWidth < 960 ? true : false,

        md: document.getElementById('scrollToEmbed').clientWidth > 960 && document.getElementById('scrollToEmbed').clientWidth < 1264 ? true : false,

        lg: document.getElementById('scrollToEmbed').clientWidth > 1264 && document.getElementById('scrollToEmbed').clientWidth < 1904 ? true : false,

        xl: document.getElementById('scrollToEmbed').clientWidth > 1904 ? true : false,

      }
    },
    // Get size of window
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },

    // Update and do the action depending on the web lead step
    updateStages(webLeadStep, keepLead = true) {
      this.loading = true;
      var vm = this;
      vm.form.webLeadStep = webLeadStep;

      // Stage 1
      if(webLeadStep == 'Address Stage') {

        vm.reCaptchaValidation('validationQuote');
      }
      // Stage 2
      if(webLeadStep == 'Service Stage' || webLeadStep == 'Redirect Stage') {
        let leadType = vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType);
        vm.sortServicePackges(leadType);
        if(keepLead){
          vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());
        }else{
          this.apiResult = cloneDeep(this.apiResultOriginal);
          this.loading = false;
        }
      }
      // Stage 3
      if(webLeadStep == 'Details Stage') {
        vm.updatedServicesWantedForm();
        if((vm.form.serviceType == 'Commercial' && vm.form.menuNavigationId == 'WasteRecyPickUp' && vm.form.servicesWantedWithPricing) || (vm.component === 'commercial')){
          vm.updateViewComponent("ProcessingOrder");
          vm.sendHttpRequestQuote('PriceInformation',useAxiosFormStore().getDataToAxios());
        } else{
          vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());
        }
      }
    },

    // go to previous component and reset some data
    back(skip) {

      if(this.form.webLeadStep == "Address Stage"){

        this.form.webLeadStep = '';
        this.form.categoryType = '';

      }
      if(this.form.webLeadStep == "Service Stage"){

        this.form.webLeadStep = 'Address Stage';

      }
      if(this.form.webLeadStep == "Redirect Stage"){

        this.form.webLeadStep = 'Service Stage';

      }

      this.loading = false;
      this.form.wantedQtyErr = false;
      this.form.menuNavigationId = null;
      this.apartmentsCondosHoas = false;

      //reset form from "add more detail btn"
      this.completeAddressMsg = '';
      this.serviceFormErrors = false;
      this.form.serviceForm = [];
      this.form.wantedSize = {};
      this.form.wantedQty = 0;
      this.form.businessName = '';
      this.form.wantedFrequency = {};
      this.form.servicesWantedJSON = null;
      this.form.businessType = '';
      this.form.serviceDetail = '';

      this.form.wantedStartDate= "";
      this.form.wantedStartDateMenu = false;
      this.form.wantedStartDateFormatted= "";

      this.form.wantedDeliveryDate= "";
      this.form.wantedDeliveryDateMenu = false;
      this.form.wantedDeliveryDateFormatted= "";

      this.form.wantedEndDate= "";
      this.form.wantedEndDateMenu = false;
      this.form.wantedEndDateFormatted= "";

      this.apiResult = cloneDeep(this.apiResultOriginal);

      if(!skip){
        this.form.menuOrder.pop();
      }

      this.trackViewSplitter = '';

      if(!skip){
        const component = this.form.menuOrder[this.form.menuOrder.length - 1];
        const componentDoubleBack = this.form.menuOrder[this.form.menuOrder.length - 2];
        if(component.includes('ResiStorefront') || component.includes('CommStorefront')){
          this.form.menuOrder.pop();
          this.updateViewComponent(componentDoubleBack);
        }else{
          this.updateViewComponent(component);
        }
      }

      this.scrollToTop();
    },

    // facebook event
    fbqSendEvents(type) {
      if (typeof window.fbq !== "undefined") {
        if(type==='location'){
          window.fbq('track', 'FindLocation')
        }else{
          if(this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType)==='Residential'){
            window.fbq('trackCustom', 'Lead Residential');
          }
          if(this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType)==='Commercial'){
            window.fbq('trackCustom', 'Lead Commercial');
          }
          if(this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType)==='Construction'){
            window.fbq('trackCustom', 'Lead Construction');
          }

        }
      }
    },

    //  ***********************************************
    //  Data packages from waste connection
    //  ***********************************************
    getLeadTypeFromServiceType(defaultService, categorieType) {
      // const isCommercial = this.containServicePackgesOf('commercial', true);
      const isYard = this.residentialMhoamWantedForm.some(form => form.size?.binSize?.toLowerCase().includes('yard'));
      let leadType = "";

      if(defaultService==="Residential") {

        switch(categorieType) {
          case 'Apartments, Condos & HOAs':
            if(isYard){ // isCommercial
              leadType = 'Commercial';
            }else{
              leadType = 'Residential';
            }
            break;
          case 'Dumpster Rental (Remodels, Construction, Etc.)':
            leadType = 'Construction';
            break;
          case 'Portable Toilets':
            leadType = 'Portable Toilet';
            break;
          default:
            leadType = defaultService;
        }
        return leadType;

      }

      if(defaultService==="Commercial"){

        switch(categorieType) {
          case 'Dumpster Rental (Construction, Bulky Pickup, Etc.)':
            leadType = 'Construction';
            break;
          case 'Portable Toilets':
            leadType = 'Portable Toilet';
            break;
          default:
            leadType = defaultService;
        }

      }
      if(defaultService==="Roll Off" && !categorieType && this.component === 'rolloff'){

        leadType = 'Construction'

      }
      return leadType;
    },

    sortServicePackges(leadType) {
      var vm = this;

      if(vm.apiResult.storeFrontURL){

        const RESI = 'RESIDENTIAL';
        const COMM = 'COMMERCIAL';
        const CONST = 'CONSTRUCTION';
        const TEMP = 'TEMP ROLL OFF';
        const TOILET = 'PORTABLE TOILET';
        const SERVICECONST = leadType.toUpperCase()===CONST?TEMP:leadType.toUpperCase();

        if(leadType.toUpperCase()===RESI){

          vm.getServicePackges(RESI);

        }else if(leadType.toUpperCase()===COMM){

          vm.getServicePackges(COMM);

        } else if(leadType.toUpperCase()===CONST){

          vm.getServicePackges(SERVICECONST);

        }else if(leadType.toUpperCase()===TOILET){

          vm.getServicePackges(TOILET+'S');

        }else{

          reset.apiStoreData(true);

        }
      }
    },

    haveServicesForThisType(){
      var vm = this;
      if(vm.apiResult.storeFrontURL && vm.form.menuNavigationId !== 'BulkPickUp' && vm.form.menuNavigationId !== "SomethingElse"){

        const RESI = 'RESIDENTIAL';
        const COMM = 'COMMERCIAL';
        const CONST = 'CONSTRUCTION';
        const TEMP = 'TEMP ROLL OFF';
        const TOILET = 'PORTABLE TOILET';
        let leadType = vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType);

        if(leadType.toUpperCase()===RESI&&!vm.duplicateCustomerCheck()){

          return vm.apiResult?.data?.ServicePackges?.filter(o => o.LineOfBusiness===RESI).length;

        }else if(leadType.toUpperCase()===COMM&&!vm.duplicateCustomerCheck()){

          return vm.apiResult?.data?.ServicePackges?.filter(o => o.LineOfBusiness===COMM).length;

        } else if(leadType.toUpperCase()===CONST){

          return vm.apiResult?.data?.ServicePackges?.filter(o => o.LineOfBusiness===TEMP).length;

        } else if(leadType.toUpperCase()===TOILET){

          return vm.apiResult?.data?.ServicePackges?.filter(o => o.LineOfBusiness===TOILET+'S').length;

        }
      }
    },
    containServicePackgesOf(type, original = false){
      let dataSource = original ? this.apiResultOriginal.data : this.apiResult.data;
      if(dataSource && dataSource.ServicePackges){
        return dataSource.ServicePackges.filter(o => o.LineOfBusiness===this.getRealNameFromPackages(type)).length?true:false
      }
      return null;
    },
    duplicateCustomerCheck(forLeadInfo){
      const result = this.apiResult.data && this.apiResult.data.StoreFrontSetting && this.apiResult.data.StoreFrontSetting.EnableDuplicateCustomerCheck && this.apiResult.data.ExistingAccounts && this.apiResult.data.ExistingAccounts.length > 0;
      const resultOriginal = this.apiResultOriginal.data && this.apiResultOriginal.data.StoreFrontSetting && this.apiResultOriginal.data.StoreFrontSetting.EnableDuplicateCustomerCheck && this.apiResultOriginal.data.ExistingAccounts && this.apiResultOriginal.data.ExistingAccounts.length > 0;
      const resultLead = this.apiResult.data && this.apiResult.data.ExistingAccounts && this.apiResult.data.ExistingAccounts.length > 0;
      const resultOriginalLead = this.apiResultOriginal.data && this.apiResultOriginal.data.ExistingAccounts && this.apiResultOriginal.data.ExistingAccounts.length > 0;

      if(forLeadInfo){
        return resultLead || resultOriginalLead;
      }

      return result || resultOriginal;
    },
    getRealNameFromPackages(name){
      const RESI = 'RESIDENTIAL';
      const COMM = 'COMMERCIAL';
      const CONST = 'CONSTRUCTION';
      const TEMP = 'TEMP ROLL OFF';
      const TOILET = 'PORTABLE TOILET';
      if(name.toUpperCase()===RESI){
        return RESI;
      }else if(name.toUpperCase()===COMM){
        return COMM;
      } else if(name.toUpperCase()===CONST){
        return TEMP;
      } else if(name.toUpperCase()===TOILET){
        return TOILET+'S';
      }
    },

    getServicePackges(type){
      var vm = this;
      vm.apiResult.data.ServicePackges = vm.apiResult?.data?.ServicePackges?.filter(o => o.LineOfBusiness===type);

      if(!vm.apiResult.data.ServicePackges.length){

        reset.apiStoreData(true);

      }
    },

    // Send request data to salesforce for update/create lead
    async sendHttpRequestQuote(apiName,json) {
      this.apiName = apiName
      this.orderView = "processing";
      this.loading = true;
      let vm = this;

      if(apiName == 'LeadInformation') {
        if(json.servicesWantedJSON){
          json.servicesWantedJSON = JSON.stringify(json.servicesWantedJSON);
        }

        try{
          if(!json.leadExternalId){
            const response = await salesforce.sendHttpRequestQuote(apiName,json);
            if (response.status === 200) {
              if (response.data && response.data.includes("EID-")) {
                // Check if data exist and data is truly an ID
                vm.form.leadExternalId = response.data;
              }
            }
          }else{
              if(vm.form.menuNavigationId === 'CommStorefront'){
                storefront.redirectToStoreFrontLead();
              }else{
                salesforce.sendHttpRequestQuote(apiName,json);
              }
          }
        }catch (error) {
          // If error, make true to display error message
          vm.errorAxios = true;
          vm.dialog = false;
          vm.loading = false;
          vm.snackbar = true;
          console.log(error);
        }

        // Request quote form fill
        if (vm.form.serviceType && vm.form.address && vm.form.email && vm.form.categoryType && vm.form.firstName && vm.form.lastName &&
          (vm.form.menuOrder.length === 3 && !vm.form.menuOrder.includes('ResiStorefront') && !vm.form.menuOrder.includes('CommStorefront')) ||
          (vm.form.menuOrder.length === 4 && vm.form.menuOrder.includes('ResiStorefront') || vm.form.menuOrder.length === 4 && vm.form.menuOrder.includes('CommStorefront'))) {

          googleApi.gTagSendEvents(vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType).toLowerCase(),0,'Lead Created',process.env.VUE_APP_GTAG_LEAD_CATEGORY,vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),vm.form.categoryType, vm.apiResult.districtID, vm.form.email, vm.form.phone);

          if(vm.form.servicesWantedOfferStatus) {

            switch(vm.form.servicesWantedOfferStatus) {
              case 'Accepted':
                // vm.updateViewComponent("ThankyouExplore");
                vm.orderView = 'summaryService'
                break;
              case 'Refused':
                vm.orderView = 'feedback'
                break;
            }

          } else {

            vm.updateViewComponent("ThankyouExplore");

          }
          vm.resetAll();

          vm.dialog = false;
        }
        // Dumpster Commercial form fill
        if (vm.form.serviceType && vm.form.address && vm.form.email && vm.form.firstName && vm.form.lastName && vm.component === 'commercial') {

          googleApi.gTagSendEvents(vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType).toLowerCase(),0,'Lead Created',process.env.VUE_APP_GTAG_LEAD_CATEGORY,vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),vm.form.categoryType, vm.apiResult.districtID, vm.form.email, vm.form.phone);

          if(vm.form.servicesWantedOfferStatus) {

            switch(vm.form.servicesWantedOfferStatus) {
              case 'Accepted':
                vm.updateViewComponent("ThankyouExplore");
                break;
              case 'Refused':
                vm.orderView = 'feedback'
                break;
            }

          } else {

            vm.updateViewComponent("ThankyouExplore");

          }
          vm.resetAll();

          vm.dialog = false;
        }

        // Roll Off form fill
        if (vm.addressVerified && vm.form.address && vm.form.email && vm.form.firstName && vm.form.lastName && vm.form.acceptConditions && vm.component === 'rolloff') {

          googleApi.gTagSendEvents(vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType).toLowerCase(),0,'Lead Created',process.env.VUE_APP_GTAG_LEAD_CATEGORY,vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),vm.form.categoryType, vm.apiResult.districtID, vm.form.email, vm.form.phone);

          if(vm.form.servicesWantedOfferStatus) {

            switch(vm.form.servicesWantedOfferStatus) {
              case 'Accepted':
                vm.resetAll();
                vm.updateViewComponent("ThankyouExplore");
                break;
              case 'Refused':
                vm.orderView = 'feedback'
                break;
            }

          } else {

                vm.updateViewComponent("ThankyouExplore");
                vm.resetAll();

          }

          vm.dialog = false;
        }


        if ((vm.form.menuNavigationId || vm.form.selectedTypeId) && vm.form.menuNavigationId != "BulkPickUp" && vm.form.menuNavigationId != "SomethingElse") {

          vm.form.menuOrder.push(vm.form.menuNavigationId || vm.form.selectedTypeId);
          vm.updateViewComponent( vm.form.menuNavigationId || vm.form.selectedTypeId);
          vm.dialog = false;
          vm.loading = false;
        }

        // Residential option sent to contact us form of Bulk Item Pikcup
        if(vm.form.menuNavigationId === 'BulkPickUp'){

          vm.servicefromquote = "bulkItemPickup"
          vm.currentTabComponent = 'ContactWidgetV3'
          vm.component = "contact"
          vm.dialog = false;
          vm.loading = false;

        }

        // Business's Last option in menu
        // will send to contact us menu
        if(vm.form.menuNavigationId == "SomethingElse") {

          vm.servicefromquote = "quote"
          vm.currentTabComponent = 'ContactWidgetV3'
          vm.component = "contact"
          vm.dialog = false;
          vm.loading = false;

        }
        vm.fbqSendEvents("");
      } else if(apiName == 'PriceInformation'){

        // Send data to backend and get the response to triggle code
        await salesforce.sendHttpRequestQuote(apiName, json)
        .then((response) => {
          if (response.data && response.data.leadExternalId.includes("EID-")) {
            // Bind external ID if it has been created
            vm.form.leadExternalId = response.data.leadExternalId;

          }

          // Get the response data and check if the pricing is available
          if(response.data.servicesWantedPricingAvailable){

            // Get the data updated from back-end side to front-end
            // with the response of the price if it exist or not
            vm.form.servicesWantedJSON = response.data.servicesWantedJSON
            vm.form.servicesWantedPricingAvailable = response.data.servicesWantedPricingAvailable;

            // If the price doesn't exist,
            // skip the view with the prices and summary
            if(!vm.form.servicesWantedWithPricing){

              vm.updateViewComponent("ThankyouExplore");
              vm.resetAll();

            }else{

              // Show view of the summary
              vm.orderView = "confirmationEmail";
              vm.form.servicesWantedOfferStatus = response.data.servicesWantedOfferStatus;

            }

          } else {
            googleApi.gTagSendEvents(vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType).toLowerCase(),0,'Lead Created','Start Service',vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),vm.form.categoryType, vm.apiResult.districtID, vm.form.email, vm.form.phone);
            vm.updateViewComponent("ThankyouExplore");
            vm.resetAll();

          }

          vm.loading = false;
          vm.fbqSendEvents("");

        }).catch(function (error) {
          if (error) {
            vm.loading = false;
            vm.errorAxios = true;
          }
          vm.loading = false;
          vm.snackbar = true;
          console.log(error);
        });
      }
    },

    // *******************************************************
    // Google
    // *******************************************************
    // RECAPTCHA

    reCaptchaValidation(dataAction){

      this.loading = true;
      var vm = this;

      load(process.env.VUE_APP_RECAPTCHA).then((recaptcha) => {
        recaptcha.execute(dataAction).then(function (token) {
          axios
            .post(
              process.env.VUE_APP_RESTURL + "services/apexrest/ServiceProvidedSearch",
              {
                callType: "validation",
                validationObject: { token: token },
              }
            )
            .then(function (response) {

              if (response.status === 200) {

                var data = JSON.parse(response.data);

                if (dataAction === 'validationQuote' && window.google_tag_manager && typeof window.dataLayer !== "undefined") {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'eventTracking',
                      action: 'Submit Address',
                      event_category: process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                      event_label: vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),
                      value: 0,
                      lead_type: vm.form.serviceType,
                      lead_category: vm.form.categoryType,
                      lead_district: vm.apiResult.districtID
                    });

                    if (data.success && data.action === dataAction && data.score >= 0.3){
                      if(vm.component === 'contact'){

                        useContactUsStore().verifyGoogleAddress('address');

                      } else{

                        vm.verifyGoogleAddress();

                      }

                    } else {
                      vm.recaptchaDialog = true;
                      vm.loading = false;
                    }

                } else {

                  if (data.success && data.action === dataAction && data.score >= 0.3){
                    if(vm.component === 'contact'){

                      useContactUsStore().verifyGoogleAddress('address');

                    } else{

                      vm.verifyGoogleAddress();

                    }

                  } else {
                    vm.recaptchaDialog = true;
                    vm.loading = false;

                  }

                }

              }
              if(vm.component === 'contact'){
                vm.loading = false;
              }
            })
            .catch(function (error) {

              vm.loading = false;
              console.log(error);

            });
        });
      });
    },
    // Check address and push multiple addresses in a array
    verifyGoogleAddress() {
          this.loading = true;

          // return promises response
          googleApi.autocompleteSuggestions(this.google, this.form.address).then((result) => {
          // check promises
          if (result.status == 'OK') {

            if (result.predictions.length === 1) {

              googleApi.gTagSendEvents("1 Address",0,"Address Response",process.env.VUE_APP_GTAG_LEAD_CATEGORY,this.form.serviceType,this.form.categoryType,this.apiResult.districtID);
              this.getAddressPrediction(this.google, result.predictions[0].description);
            } else if (result.predictions[0].description === this.form.address) {

              googleApi.gTagSendEvents("1 Address",0,"Address Response",process.env.VUE_APP_GTAG_LEAD_CATEGORY,this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),this.form.categoryType,this.apiResult.districtID);
              this.getAddressPrediction(this.google,result.predictions[0].description);

            } else {

              googleApi.gTagSendEvents("Multiple Address",0,"Address Response",process.env.VUE_APP_GTAG_LEAD_CATEGORY,this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),this.form.categoryType,this.apiResult.districtID);

              var currentAddress = {
                  description: "other",
                  structured_formatting: {
                  main_text: i18n.t("continueWithThisAddress"),
                  secondary_text: this.form.address,
                },
              }

              result.predictions.push(currentAddress);
              this.form.predictions = result.predictions;
              this.viewForm("prediction");
              this.apiResult = {
                data: null,
                storeFrontURL: "",
                districtID: "",
              },
              this.apiResultOriginal = {
                data: null,
                storeFrontURL: "",
                districtID: "",
              },
              this.loading = false;

            }
          } else {
            this.completeAddressMsg = i18n.t("completeAddressRequired");
            this.loading = false;
          }
        })
        .catch((error) => {
          console.error("Error getting address suggestions:", error);
          this.completeAddressMsg = i18n.t("completeAddressRequired");
          this.loading = false;
        });
    },

    // Get the selected address from selects/options list and bind them to their variables
    getAddressPrediction(google,prediction){
          this.loading = true;

          googleApi.selectedPrediction(google, prediction).then((result) => {

              // check promises
            if (result.status == "OK") {

              // if address is found, split address in parts
              this.form.streetNumber = result.streetNumber;
              this.form.route = result.route;
              this.form.street = result.streetNumber + " " + result.route;
              this.form.city = result.city;
              this.form.state = result.state;
              this.form.zip = result.zip;
              this.form.country = result.country;
              this.form.lat = result.lat;
              this.form.lng = result.lng;
              this.form.address = result.address;

              if (this.assigntodistrict) {

                this.findTheDistrict(this.assigntodistrict);

              }

              let skip = (this.form.serviceType === "Commercial" && this.form.country.toLowerCase()==='Canada') || this.form.serviceType === "shredding" ? false : true;

              if (skip) {

                this.findAvailableServices(this.newStoreApi,this.form.street,this.form.city,this.form.zip,this.form.lat,this.form.lng,this.form.country === "Canada" ? "" : "",this.storefrontDistrictCodeOverride,"","","","",this.form.country === "Canada" ? "FR" : "EN",this.form.state);

              } else {

                googleApi.gTagSendEvents(
                  this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
                  0,
                  "Display Services List",
                  process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                  this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
                  this.form.categoryType,
                  this.apiResult.districtID
                );


                if(this.component === 'commercial' && this.currentTabComponent === 'DetailDumpster'){

                  this.updateStages('Details Stage');

                } else{

                  this.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());

                }
              }

            } else {

              googleApi.gTagSendEvents("Not Found", 0,"Address Response", process.env.VUE_APP_GTAG_LEAD_CATEGORY, this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),this.form.categoryType,this.apiResult.districtID);
              this.form.street = this.form.address;
              let himself = this.form.address;
              this.form.address = himself;
              reset.apiStoreData();
              this.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());

            }
          });
    },

    viewForm(viewFormSelected,skipScroll) {

      this.view.prediction = false;
      this.view.hoa = false;
      this.view.tenant = false;
      this.view.hoaAndTenant = false;
      this.dialog = false;

      if (viewFormSelected === "prediction") {

        this.view.prediction = true;
        this.dialog = true;

      }
      if (viewFormSelected === "hoa") {

        this.view.hoa = true;
        this.dialog = true;

      }
      if (viewFormSelected === "tenant") {

        this.view.tenant = true;
        this.dialog = true;

      }
      if (viewFormSelected === "hoaAndTenant") {

        this.view.hoaAndTenant = true;
        this.dialog = true;

      }
      if (!skipScroll) {

        this.scrollToTop();

      }
    },

    //  ********************************************************
    //  STOREFRONT
    //  ********************************************************
    // Check available services for WC after entering address
    findAvailableServices(callType, address, city, zip, latitude, longitude, line,district,lastName,firstName,email,phone,displayLanguage,state) {
      this.loading = true;
      let vm = this;

      // Skip very specific districts to go to storefront
      const districtsToSkip = ['7151', '7110'];

      storefront.findAvailableServices(callType,address,city,zip,latitude,longitude,line,district,lastName,firstName,email,phone,displayLanguage,state).then((response) => {

        if (response.status === 200 && vm.isJsonValid(response.data)) {
          vm.apiResult.data = JSON.parse(response.data);
          vm.apiResultOriginal.data = JSON.parse(response.data);

          if (vm.apiResult.data && vm.apiResult.data.District && !districtsToSkip.includes(vm.apiResult.data.District.replace('A', '')) && vm.apiResult.data.StoreFrontSetting && vm.apiResult.data.DistrictDetail && vm.apiResult.data.ServicePackges && vm.apiResult.data.DistrictDetail.storefrontLink && vm.apiResult.data.ServicePackges.length > 0 ) {

            vm.apiResult.storeFrontURL = vm.apiResult.data.DistrictDetail.storefrontLink;
            vm.apiResult.districtID = vm.apiResult.data.District;
            vm.apiResultOriginal.storeFrontURL = vm.apiResultOriginal.data.DistrictDetail.storefrontLink;
            vm.apiResultOriginal.districtID = vm.apiResultOriginal.data.District;

            if(vm.apiResult.data.StoreFrontSetting && vm.apiResult.data.StoreFrontSetting.Storefront_HOA_Enabled && !vm.apiResult.data.StoreFrontSetting.EnableCustomerTenantCheck && vm.form.serviceType === "Residential") {

              googleApi.gTagSendEvents(
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
                0,
                "Display Homeowner Association",
                process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
                this.form.categoryType,
                this.apiResult.districtID
              );

              vm.loading = false;
              this.viewForm('hoa');

            } else if (vm.apiResult.data.StoreFrontSetting && !vm.apiResult.data.StoreFrontSetting.Storefront_HOA_Enabled && vm.apiResult.data.StoreFrontSetting.EnableCustomerTenantCheck && vm.form.serviceType === "Residential") {

              googleApi.gTagSendEvents(
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
                0,
                "Display Tenant",
                process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
                this.form.categoryType,
                this.apiResult.districtID
              );

              vm.loading = false;
              this.viewForm('tenant');

            } else if (vm.apiResult.data.StoreFrontSetting && vm.apiResult.data.StoreFrontSetting.Storefront_HOA_Enabled && vm.apiResult.data.StoreFrontSetting.EnableCustomerTenantCheck && vm.form.serviceType === "Residential") {

              googleApi.gTagSendEvents(
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
                0,
                "Display Homeowner Association And Tenant",
                process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
                this.form.categoryType,
                this.apiResult.districtID
              );

              vm.loading = false;
              this.viewForm('hoaAndTenant');

            }else {
              googleApi.gTagSendEvents(
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
                0,
                "Display Services List",
                process.env.VUE_APP_GTAG_LEAD_CATEGORY,
                this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
                this.form.categoryType,
                this.apiResult.districtID
              );

              if (vm.apiResult.data.StoreFrontSetting && vm.apiResult.data.StoreFrontSetting.EnableDuplicateCustomerCheck && vm.apiResult.data.ExistingAccounts && vm.apiResult.data.ExistingAccounts.length > 0 && !vm.containServicePackgesOf('construction')) {

                reset.apiStoreData();
                vm.loading = false;

              }
              if(vm.component === 'commercial' && vm.currentTabComponent === 'DetailDumpster'){

                vm.updateStages('Details Stage');
                vm.loading = false;

              } else if(vm.component === 'quote') {

                vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());

              }else {

                vm.loading = false;

              }
            }
          } else {

            googleApi.gTagSendEvents(
              this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
              0,
              "Display Services List",
              process.env.VUE_APP_GTAG_LEAD_CATEGORY,
              this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
              this.form.categoryType,
              this.apiResult.districtID
            );

            vm.apiResult.districtID = vm.apiResult.data.District;
            vm.apiResultOriginal.districtID = vm.apiResultOriginal.data.District;
            reset.apiStoreData(true);

            if(vm.component === 'commercial' && vm.currentTabComponent === 'DetailDumpster'){

              vm.updateStages('Details Stage');
              vm.loading = false;

            } else if(vm.component === 'quote') {

              vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());

            }else {

              vm.loading = false;

            }
          }
        } else {
          googleApi.gTagSendEvents(
            this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
            0,
            "Display Services List",
            process.env.VUE_APP_GTAG_LEAD_CATEGORY,
            this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
            this.form.categoryType,
            this.apiResult.districtID
          );

          if(vm.component === 'commercial' && vm.currentTabComponent === 'DetailDumpster'){

            vm.updateStages('Details Stage');
            vm.loading = false;

          } else if(vm.component === 'quote') {

            vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());

          } else {

            vm.loading = false;

          }
        }
        vm.addressVerified = true;
      }).catch(function (error){
        googleApi.gTagSendEvents(
          vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType).toLowerCase(),
          0,
          "Display Services List",
          process.env.VUE_APP_GTAG_LEAD_CATEGORY,
          vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),
          vm.form.categoryType,
          vm.apiResult.districtID
        );

        if(vm.component === 'commercial' && vm.currentTabComponent === 'DetailDumpster'){

          vm.updateStages('Details Stage');
          vm.dialog = false;
          vm.loading = false;

        } else if(vm.component === 'quote') {

          vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());

        } else {
          vm.dialog = false;
          vm.loading = false;
        }
        console.log(error);

      })
    },

    isJsonValid(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    },

    homeOwnerAssociationOrTenant(){
        let vm = this;
        googleApi.gTagSendEvents(
          this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType).toLowerCase(),
          0,
          "Display Services List",
          process.env.VUE_APP_GTAG_LEAD_CATEGORY,
          this.getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType),
          this.form.categoryType,
          this.apiResult.districtID
        );
        if(vm.component === 'commercial' && vm.currentTabComponent === 'DetailDumpster'){
          vm.updateStages('Details Stage');
        } else if(vm.component === 'quote') {
          vm.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());
        }
    },

    //  ********************************************************
    //  REQUEST QUOTE
    //  ********************************************************
    // Toggle the service type to assign to Residential or Business
    warn(toggle,event){
      if(this.form.serviceType != event || this.form.type != event){
      toggle()
      }
    },

    // Fill the SVG with the color depending on the trigger action for Residential
    fillSvgResidential(active,hover){
        if(!active && hover){

          return 'green-svg'

        }
        if(!active && !hover && this.form.serviceType){

          return 'green-svg'

        }
        if(active){

          return 'white-svg'

        }

          return 'green-svg'

    },

    // Fill the SVG with the color depending on the trigger action for Business
    fillSvgBusiness(active,hover){
        if(!active && hover){

          return 'blue-svg'

        }
        if(!active && !hover && this.form.serviceType){

          return 'blue-svg'

        }
        if(active){

          return 'white-svg'

        }
          return 'blue-svg'
    },

    // Fill the background color depending on the trigger action for Residential
    classSvgResidential(active,hover){
        if(!active && hover){

          return "green-hover-svg"

        }
        if(!active && !hover && this.form.serviceType){

          return 'green-default-svg'

        }
        if(active){

          return 'green-active-svg'

        }

          return 'green-default-svg'
    },
    // Fill the background color depending on the trigger action for Business
    classSvgBusiness(active,hover){
        if(!active && hover ){
          return 'blue-hover-svg'
        }
        if(!active && !hover && this.form.serviceType){

          return 'blue-default-svg'

        }
        if(active){

          return 'blue-active-svg'

        }

        return 'blue-default-svg'
    },
    // Assign district by default
    findTheDistrict(district) {
      var vm = this;
      axios
        .post(process.env.VUE_APP_RESTURL + "services/apexrest/ServiceProvidedSearch", {
          callType: "findTheDistrict",
          serviceObject: { districtID: district },
        })
        .then(function (response) {

          if (response.status === 200) {

            let districtID = JSON.parse(response.data);

            if (districtID && districtID.data) {

              vm.form.assignToDistrict = districtID.data;

            }

          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },

    // Remove forms that has no data and return forms that has been filled
    filterExistOnly(objectArray){
      let filteredDumpsters = objectArray.filter((item) => {

        return item.qty || item.frequency.pickupFrequency || item.size.binSize || item.offeredPrice || ''

      })

      return filteredDumpsters.length ? filteredDumpsters : '';

    },

    // Update form and bind to form.servicesWantedForm
    updatedServicesWantedForm(bool){
      // for pricing cards
      if(this.form.servicesWantedJSON){
        this.summaryList = this.form.servicesWantedJSON;
      }
        let json = ''

      if(this.component === 'quote') {

        // Verify which form will be send to salesforce
        if(this.form.menuNavigationId === 'ResiWastePickUp'){

          json = this.filterExistOnly(this.residentialWantedForm);

        } else if(this.form.menuNavigationId === 'MhoamResidential'){

          json = this.filterExistOnly(this.residentialMhoamWantedForm);

        } else if(this.form.menuNavigationId === 'WasteRecyPickUp'){

          const commercialWantedForm = this.filterExistOnly(this.commercialWantedForm);

          if(this.form.servicesWantedWithPricing && !commercialWantedForm){
            json = []
          } else {
            json = commercialWantedForm;
          }

        } else if(this.form.menuNavigationId === 'MhoamView'){

          json = this.filterExistOnly(this.commercialMhoamWantedForm);

        } else if(this.form.menuNavigationId === 'CompIndustWaste'){
          let i = 0;
          this.jsonForm = [];
          while(i < this.form.serviceForm.length) {
            let objectService = {
              title: this.form.categoryType,
              size: this.form.wantedSize,
              qty: this.form.wantedQty,
              frequency: this.form.wantedFrequency,
            };
            objectService.title = this.form.serviceForm[i];
            objectService.icon = this.form.serviceForm[i].trim().toLowerCase();
            this.jsonForm.push(objectService);
            i++;
          }

          json = this.jsonForm;

        } else if(this.form.menuNavigationId === 'DumpsterRental'){

          // Code will fuse individual field that has no form card to the JSON object
          if(this.form.wantedSize || this.form.wantedQty || this.form.wantedFrequency){
            this.jsonForm = [{
              title: this.form.categoryType,
              size: this.form.wantedSize,
              qty: this.form.wantedQty,
              frequency: this.form.wantedFrequency,
            }]
          }else{
            this.jsonForm = '';
          }

          json = this.jsonForm

        }
      }
      else if(this.component === 'commercial' || this.component === 'rolloff') {
        json = [useDumpsterCommercialStore().dumpsterSelected];
      }

      if(!bool){
        this.form.servicesWantedJSON = json;
      }

      return json
    },

    // Convert a Array to String and change delimiter to ";"
    formatSurvey(array){
      if(array.length){

        return array.join(';') + ';';

      }
    },

    // Reset form from v-card when toggled in request quote
    clearCard(toggle, id, form){

      toggle();
      for (let index = 0; index < form.length; index++) {
        if(id === index){

          form[index].qty = 0;
          form[index].size = {};
          form[index].frequency = {};

        }
      }
    },
    // Check and return component name to its initial ( used after resetting all variables to default) )
    getIndexComponent(){
      let index = ''

      if(this.component === 'quote'){

        this.currentTabComponent = "ServiceView"
        this.form.webSourceForm = process.env.VUE_APP_GTAG_LEAD_CATEGORY

      }

      else if(this.component === 'commercial'){

        this.currentTabComponent = "ChooseDumpster"
        this.form.serviceType = "Commercial"
        this.form.webSourceForm = "Commercial Chart Embed - Generic"

      }

      else if(this.component === 'rolloff'){

        this.currentTabComponent = "ChooseRollOff"
        this.form.serviceType = 'Roll Off'
        this.form.webSourceForm = "Roll Off Service Embed - Generic"

      }

      index = this.currentTabComponent;
      return index
    },

    // reset variables to defaults
    resetAll(){
      reset.commercialChart();
      reset.quote();
      reset.user();
      reset.address();
      reset.apiStoreData();
    },
  },
})