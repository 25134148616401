<template>
  <v-row>
    <v-col offset="1" cols="10">
      <v-form ref="wasteForm" v-model="formValid" lazy-validation>
        <!-- <v-row>
          <v-card-title class="mx-auto mobile-my-20 text-title">
            Compactors & Industrial Waste
          </v-card-title>
        </v-row> -->
        <v-col class="pa-0 min-height-44 d-flex align-center">
          <BackBtn></BackBtn>
          <icon class="flex-grow-1" svg='step-marker-3'/>
        </v-col>
        <v-row>
          <v-col>
            <v-item-group>
              <v-item>
                  <v-card class="my-7 rounded-lg text-center" :class="form.serviceType == 'Residential' ? 'green-active-svg' : 'blue-active-svg'">
                    <v-row>
                      <v-col>
                        <v-list-item>
                          <v-spacer v-if="$vuetify.breakpoint.xs ? false : true "></v-spacer>
                          <icon :svg="form.svgCategorySelected" class="white-svg" />
                          <v-list-item-content>
                            <v-list-item-title class="pl-5 card-label text-wrap white--text" >
                            {{ i18n.t('compactorsAndIndustrialWaste') }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-spacer v-if="$vuetify.breakpoint.xs ? false : true "></v-spacer>
                          <icon svg="checkmark" />
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card>
              </v-item>
            </v-item-group>
          </v-col>
        </v-row>
        <!-- Display extra form if need more details Waste Recycling and Pickup -->
        <v-row>
          <v-col class="py-0">
            <v-card-text class="px-0 py-0 mb-1 text-label">
              {{i18n.t("businessName")}}
            </v-card-text>
            <v-text-field dense flat :placeholder="i18n.t('businessNamePlaceholder')"
              v-bind:rules="formRules.businessNameRules"  v-bind:disabled="loading" v-model="form.businessName"
              class="rounded-lg input-label" solo outlined prepend-inner-icon="mdi-store" color="grey"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-card-text class="px-0 py-0 mb-1 text-label">
              {{i18n.t("tellUsWhat")}}
            </v-card-text>
            <v-textarea flat v-model="form.description" v-bind:disabled="loading"
              v-bind:rules="formRules.descriptionRules"
              height="90px" :placeholder="i18n.t('descriptionPlaceholder')" solo outlined no-resize
              class="rounded-xl-description input-label" color="grey"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="py-0">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="rgb(240, 247, 254)" class="rounded-lg box-shadow-custom">
                  <template>
                    <v-row no-gutters align="center">
                      <v-icon color="secondary" left>mdi-wrench-cog-outline</v-icon>
                      <span class="text-subtitle-2 secondary--text">{{ i18n.t("setUpYourServices") }}</span>
                    </v-row>
                  </template>
                  <template v-slot:actions>
                    <v-icon color="secondary">
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-item-group mandatory multiple v-model="form.serviceForm" class="pt-5">
                    <v-row class="justify-center">
                      <v-col class="mt-4 pb-0 mb-1 text-center" sm="4" md="2" v-for="serviceType in servicesTypes" :key="serviceType.name">
                        <v-item :value="serviceType.text">
                          <template v-slot="{ active, toggle }">
                            <v-btn @mouseover="hoverEvent(serviceType.id)" @mouseleave="hoverEvent(null)" width="83px" height="72px" class="rounded-lg" :class="active ? 'blue-active-svg' : 'blue-hover-svg '" @click="toggle">
                              <icon v-if="!active && hover != serviceType.id" class="blue-svg" :svg="serviceType.icon"></icon>
                              <icon v-if="active" class="white-svg" :svg="serviceType.icon"/>
                              <icon v-if="hover === serviceType.id && !active" class="blue-hover-fill-svg" :svg="serviceType.icon"/>
                              <icon v-if="active" svg="checkmark" class="checkmark-compactors"/>
                            </v-btn>
                          </template>
                        </v-item>
                        <v-card-title class="subtitle-2 justify-center px-0">
                          {{i18n.locale==='fr'?serviceType.textFr:serviceType.text}}
                        </v-card-title>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row v-if="!formValid" class="pt-5">
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2" >
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField') }}
            </div>
          </div>
        </v-row>
        <!-- BACK & CONTINUE -->
        <div class="mt-6">
          <!-- <v-btn :width="$vuetify.breakpoint.xs ? '39%': button.back.width" class="btn-label rounded-lg white--text mr-2 flex-grow-0 accent lighten-1" height="70px"  @click="loading ? null : back()">
            <v-icon x-large left>
              mdi-chevron-left
            </v-icon>
            <v-spacer></v-spacer>
            {{ i18n.t("back") }}
            <v-spacer></v-spacer>
          </v-btn>
          <v-btn :width="$vuetify.breakpoint.xs ? '58%': button.continue.width" v-if="apiResult.storeFrontURL && getLeadTypeFromServiceType(form.serviceType, form.categoryType) == 'Residential'" :color="!formValid? 'error' : 'success'" outlined height="70px" class="btn-label rounded-lg " :class=" !formValid? 'red-outlined' : 'green-outlined'" :style="windowSize.x < 475 ? 'font-size: 14px !important' : ''" v-ripple="loadingWithService ? false : true" @click="loadingWithService ? null : validateWithService()">
            <v-spacer></v-spacer>
                    {{ loadingWithService ? i18n.t("validate") : i18n.t("goToStore") }}              <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
          <v-btn :width="$vuetify.breakpoint.xs ? '58%': button.continue.width" v-else :color="!formValid? 'error' : 'success'" height="70px" class="btn-label rounded-lg" v-ripple="loading ? false : true" @click="loading ? null : validate()">
            <v-spacer></v-spacer>
                    {{ loading ? i18n.t("validate") : i18n.t("getMyQuote") }}
          <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn> -->
          <ForwardBtn :parentRefs="$refs" currentTabComponent="DetailView"></ForwardBtn>
        </div>
        <!-- <div v-if="apiResult.storeFrontURL && getLeadTypeFromServiceType(form.serviceType, form.categoryType) !== 'Residential'"  class="d-flex flex-row mt-6 justify-end">
          <v-btn :width="$vuetify.breakpoint.xs ? '58%': button.continue.width" :block="windowSize.x < 475 ? true : false" :color="!formValid? 'error' : 'success'" outlined height="70px" class="btn-label rounded-lg" :class=" !formValid? 'red-outlined' : 'green-outlined'" v-ripple="loadingWithService ? false : true" @click="loadingWithService ? null : validateWithService()">
            <v-spacer></v-spacer>
              {{ loadingWithService ? i18n.t("validate") : i18n.t("goToStore") }}
            <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
        </div> -->
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import icon from "../../utility/SvgLoader";
import { useIndexStore } from '../../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import ForwardBtn from "../../utility/forwardBtn.vue"
import BackBtn from "../../utility/backBtn.vue"

export default {
  name: "CompIndustWaste",
  components:{icon,BackBtn,ForwardBtn},
  props: {
    canada: Boolean,
    terms: String,
    privacy: String,
    name: String,
    lang: String,
  },
  data() {
    return {
      i18n: i18n,
      hover: -1,
      servicesTypes: [
        {
          id: 1,
          name: "Compactor",
          text: i18n.t('compactor', 'en'),
          textFr: i18n.t('compactor', 'fr'),
          icon: "compactorBtn",
        },
        {
          id: 2,
          name: "RollOffContainer",
          text: i18n.t('rollOffContainer' , 'en'),
          textFr: i18n.t('rollOffContainer' , 'fr'),
          icon: "rolloffcontainerBtn",
        },
        {
          id: 3,
          name: "Dumpster",
          text: i18n.t('dumpster', 'en'),
          textFr: i18n.t('dumpster', 'fr'),
          icon: "dumpsterBtn",
        },
      ],
    };
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapState(useIndexStore, [
      "formRules",]),
    ...mapWritableState(useIndexStore, [
      "formErrors",
      "windowSize",
      "apiResult",
      "formValid",
      "loading",
      'loadingWithService',
      "button"
    ]),
    ...mapWritableState(useUserStore, [
      "form",
      ]),
  },
  watch: {},
  methods: {
    ...mapActions(useIndexStore, ['onResize','updateViewComponent','back','sendInfoWithService','getLeadTypeFromServiceType','updateStages','acceptNumber']),
    hoverEvent(id) {
      this.hover = id;
    },
    validateWithService() {
      this.loadingWithService = true;
      var vm = this
      if (vm.$refs.wasteForm.validate()) {
        vm.form.webLeadStep = "Details Stage";
        vm.sendInfoWithService()
      }else{
        vm.loadingWithService = false;
      }
    },
    validate() {
      this.loading = true;
      var vm = this;
      if (vm.$refs.wasteForm.validate()) {
        vm.updateStages("Details Stage");
      } else {
        vm.loading = false;
      }
    },
  },
};
</script>

<style scoped>
  .min-height-44 {
    min-height: 44px;
  }
  .box-shadow-custom {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  }
  ::v-deep .v-expansion-panel::before {
    box-shadow: none !important;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: initial;
  }
</style>