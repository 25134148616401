<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col :offset="$vuetify.breakpoint.xs ? 0 : 1" :cols="$vuetify.breakpoint.xs ? 12 : 10">
          <v-card-text primary-title class="text-title mb-0 px-0" :class="headerstore ? 'text-left' : 'text-center'">
            {{ i18n.t('chooseYourResidentialService') }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-col class="pa-0 min-height-44 d-flex align-center" offset="1" cols="10">
        <BackBtn></BackBtn>
        <icon class="flex-grow-1" svg='step-marker-2' />
      </v-col>
      <v-col class="pa-0 d-flex align-center" offset="1" cols="10">
        <AlertBox></AlertBox>
      </v-col>
      <!-- List of Residential Services -->
      <v-form ref="wasteForm" v-model="formValid" lazy-validation>
        <v-carousel height="100%" :hide-delimiters="true" hide-delimiter-background :show-arrows="false"
          :continuous="false">
          <template v-slot:prev="{ on, attrs }">
            <v-btn small fab color="success" v-bind="attrs" v-on="on">
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn small fab color="success" v-bind="attrs" v-on="on">
              <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <v-col offset="1" cols="10" class="px-0">
            <v-carousel-item v-for="(slide, i) in slideshow" :key="i">
              <v-list>
                <v-list-item-group transition="scroll-x-transition" v-model="form.menuNavigationId" @change="onChangeServiceList()">
                  <v-list-item v-slot="{ active }" class="green-default-svg rounded-lg my-4 py-4"
                    v-for="(item, i) in slide" :key="i" :value="item.name" height="85px" v-bind:disabled="loading"
                    v-ripple="loading ? false : true" active-class="green-active-svg"
                    @click="form.categoryType = item.text; form.svgCategorySelected = item.icon">
                    <icon v-if="!active" contain width="50px" max-height="51px" :svg="item.icon" class="green-svg ml-4"></icon>
                    <icon v-if="active" contain width="50px" max-height="51px" :svg="item.icon" class="white-svg ml-4"></icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 pl-6 pr-3" :class="active ? 'white--text' : 'black--text'">
                        <span>{{ i18n.locale === 'fr' ? item.textFr : item.text }}</span>
                        <v-icon v-if="item.name === 'ResiWastePickUp' && containServicePackgesOf('residential')" right color="secondary">mdi-open-in-new</v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                    <icon v-if="active" contain max-width="76px" max-height="71px" svg="checkmark"></icon>
                    <v-fade-transition>
                      <v-overlay v-if="item.name === 'ResiWastePickUp' && loadingWithService" absolute opacity="0.16">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                      </v-overlay>
                    </v-fade-transition>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-carousel-item>
          </v-col>
        </v-carousel>
        <v-row v-if="!formValid && !this.form.menuNavigationId" class="my-3">
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2">
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField')
              }}
            </div>
          </div>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import icon from "../../utility/SvgLoader";
import { useIndexStore } from '../../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import BackBtn from "../../utility/backBtn.vue"
import AlertBox from "../../../sfc/Alert.vue"
import googleApi from "@/plugins/google"
import storefront from "@/plugins/storefront"

export default {
  name: "ResidentialService",
  components: { icon, BackBtn, AlertBox },
  data() {
    return {
      i18n: i18n,
      slideshow: {
        garbagesType: [
          {
            id: 1,
            name: "ResiWastePickUp",
            text: i18n.t('wasteAndRecyclingPickup', 'en'),
            textFr: i18n.t('wasteAndRecyclingPickup', 'fr'),
            icon: "ResiWastePickUp",
          },
          {
            id: 2,
            name: "BulkPickUp",
            text: i18n.t('bulkyItemPickup', 'en'),
            textFr: i18n.t('bulkyItemPickup', 'fr'),
            icon: "BulkPickUp",
          },
          {
            id: 3,
            name: "DumpsterRental",
            text: i18n.t('dumpsterRental', 'en'),
            textFr: i18n.t('dumpsterRental', 'fr'),
            icon: "DumpsterRental",
          },
          {
            id: 4,
            name: "MhoamResidential",
            text: i18n.t('apartmentsCondosHOAs', 'en'),
            textFr: i18n.t('apartmentsCondosHOAs', 'fr'),
            icon: "MhoamView",
          },
          {
            id: 5,
            name: "MunicipalitiesView",
            text: i18n.t('municipalities', 'en'),
            textFr: i18n.t('municipalities', 'fr'),
            icon: "Municipalities",
          },
        ],
        garbagesType1: [
          // {
          //   id: 5,
          //   name: "MunicipalitiesView",
          //   text: "Municipalities",
          //   icon: "Municipalities",
          // },
          // {
          //   id: 6,
          //   name: "FoodWaste",
          //   text: "Food Waste",
          //   icon: "OrganicWaste",
          // },
          // {
          //   id: 7,
          //   name: "YardWaste",
          //   text: "Yard Waste",
          //   icon: "YardWaste",
          // },
          // {
          //   id: 8,
          //   name: "PortableToilets",
          //   text: "Portable Toilets",
          //   icon: "PortableToilets",
          // },
        ],
        // garbagesType2:[
        //   {
        //     id: 9,
        //     name: "SomethingElse",
        //     text: "Something Else?",
        //     subtitle: "Send us a message",
        //     icon: "question",
        //   },
        // ],
      },
    };
  },
  mounted: function () {
    const hostName = location.hostname;
    if (hostName !== 'www.baydisposal.com') {
      const portableToilets = { id: 6, name: "PortableToilets", text: this.i18n.t('portableToilets', 'en'), textFr: this.i18n.t('portableToilets', 'fr'), icon: "PortableToilets", };
      const somethingElse = { id: 7, name: "SomethingElse", text: this.i18n.t('somethingElse', 'en'), textFr: this.i18n.t('somethingElse', 'fr'), icon: "question", };
      this.slideshow.garbagesType.push(portableToilets);
      this.slideshow.garbagesType.push(somethingElse);
    }
    if(this.servicesofferedResi){
      const filteredObject = Object.keys(this.slideshow).reduce((acc, curr) => {
        acc[curr] = this.slideshow[curr].filter((item) => this.servicesofferedResi.includes(item.name));
        return acc;
      }, {});
      this.slideshow = filteredObject;
    }
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapState(useIndexStore, [
      "showGetAQuote",
      "showGoToStore",
      "headerstore",
      "servicesofferedResi",
    ]),
    ...mapWritableState(useIndexStore, [
      'formValid',
      'loading',
      'apiResult',
      'loadingWithService',
    ]),
    ...mapWritableState(useUserStore, [
      "form",
    ]),
  },
  methods: {
    ...mapActions(useIndexStore, [
      'back',
      'updateStages',
      'updateViewComponent',
      'getLeadTypeFromServiceType',
      'containServicePackgesOf',
    ]),
    onChangeServiceList(){
      if (this.loadingWithService) return;

      const leadType = this.getLeadTypeFromServiceType(this.form.serviceType, this.form.categoryType).toLowerCase();
      const gtagParams = [leadType, 0, "Display Lead Field", process.env.VUE_APP_GTAG_LEAD_CATEGORY, this.getLeadTypeFromServiceType(this.form.serviceType, this.form.categoryType), this.form.categoryType, this.apiResult.districtID];

      if(this.form.categoryType === "Apartments, Condos & HOAs"){
        googleApi.gTagSendEvents(...gtagParams);
        this.updateStages("Redirect Stage");
      } else if(this.showGetAQuote && this.showGoToStore) {
        googleApi.gTagSendEvents(...gtagParams);
        this.updateViewComponent('ResiStorefront');
        this.form.menuOrder.push("ResiStorefront");
        this.form.webLeadStep = "Redirect Stage";
      } else if(!this.showGetAQuote && this.showGoToStore && this.form.categoryType !== "Municipalities") {
        this.loadingWithService = true;
        this.form.webLeadStep = "Redirect Stage";
        storefront.sendInfoWithService();
      } else {
        googleApi.gTagSendEvents(...gtagParams);
        this.updateStages("Redirect Stage");
      }
    },
  },
};
</script>

<style scoped>.min-height-44 {
  min-height: 44px;
}</style>
