<template>
  <v-row>
    <v-col offset="1" cols="10">
      <v-form ref="wasteForm" v-model="formValid" lazy-validation>
        <div>
          <!-- <v-row>
            <v-col class="mobile-my-10">
              <v-card-title class="text-title justify-center">
                <icon svg="mhoam" max-width="50px" max-height="52px" :class="form.selectedTypeId == 'ResidentialService' ? 'green-svg' : 'blue-svg'"/>
                <p :class="windowSize.x > 475 ? 'my-0 ml-6' : 'my-0'">
                  Apartments, Condos & HOAs
                </p>
              </v-card-title>
            </v-col>
          </v-row> -->
          <v-col class="pa-0 min-height-44 d-flex align-center">
            <BackBtn></BackBtn>
            <icon class="flex-grow-1" svg='step-marker-3'/>
          </v-col>
          <v-row>
            <v-col>
              <v-item-group>
                <v-item>
                    <v-card class="my-7 rounded-lg text-center" :class="form.serviceType == 'Residential' ? 'green-active-svg' : 'blue-active-svg'">
                      <v-row>
                        <v-col>
                          <v-list-item>
                            <v-spacer v-if="$vuetify.breakpoint.xs ? false : true"></v-spacer>
                            <icon :svg="form.svgCategorySelected" class="white-svg" />
                            <v-list-item-content>
                              <v-list-item-title class="pl-5 card-label text-wrap white--text">
                                {{ i18n.t('apartmentsCondosHOAs') }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-spacer v-if="$vuetify.breakpoint.xs ? false : true"></v-spacer>
                            <icon svg="checkmark" />
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-card>
                </v-item>
              </v-item-group>
            </v-col>
          </v-row>
        <!-- Display extra form if need more details Waste Recycling and Pickup -->
        <v-row>
          <v-col class="py-0">
            <v-card-text class="px-0 py-0 mb-1 text-label">
                {{i18n.t("tellUsWhat")}}
            </v-card-text>
            <v-textarea flat v-model="form.description" v-bind:disabled="loading"
              v-bind:rules="formRules.descriptionRules"
              height="90px" :placeholder="i18n.t('descriptionPlaceholder')" solo outlined no-resize
              class="rounded-xl-description input-label" color="grey"></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col class="py-0">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header color="rgb(240, 247, 254)" class="rounded-lg box-shadow-custom">
                  <template>
                    <v-row no-gutters align="center">
                      <v-icon color="secondary" left>mdi-wrench-cog-outline</v-icon>
                      <span class="text-subtitle-2 secondary--text">{{ i18n.t("setUpYourServices") }}</span>
                    </v-row>
                  </template>
                  <template v-slot:actions>
                    <v-icon color="secondary">
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-item-group multiple class="pt-5">
                    <v-row>
                      <v-col class="mt-4 pb-0 mb-1" v-for="serviceWantedForm in residentialMhoamWantedForm" :key="serviceWantedForm.id" :cols="$vuetify.breakpoint.xs ? '12': '6'">
                          <v-item v-slot="{ active }">
                            <v-card class="mb-4 rounded-lg text-center box-shadow-custom" color="rgb(240, 247, 254)">
                              <v-row>
                                  <v-col class="cursor-pointer">
                                    <v-list-item>
                                        <icon :svg="serviceWantedForm.icon" class="blue-svg" />
                                        <span>&nbsp;</span>
                                        <icon :svg="serviceWantedForm.icon2" class="blue-svg" />
                                        <v-list-item-content>
                                          <v-list-item-title class="ml-4 card-label text-wrap text-left">
                                            {{i18n.locale === 'fr' ? serviceWantedForm.titleFr : serviceWantedForm.title}}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                  </v-col>
                              </v-row>
                              <v-row v-if="!active">
                                <v-col class="mx-3 py-0">
                                  <v-card-text class="px-0 py-0 mb-1 text-label text-left">
                                      {{i18n.t("quantity")}}
                                  </v-card-text>
                                  <NumberInput v-model="serviceWantedForm.qty" :windowSize="windowSize" v-bind:disabled="loading"></NumberInput>
                                  <v-card-text class="px-0 py-0 mb-1 text-label text-left">
                                      {{i18n.t("size")}}
                                  </v-card-text>
                                  <v-select return-object :items="commServicesSizes || filteredSizesWasteRecycling" :item-text="i18n.locale==='fr'?'textFr':'text'"
                                  item-value="binSize" attach
                                  v-bind:rules="formRules.cartSizeRules" :placeholder="i18n.t('iAmNotSure')"
                                  v-bind:disabled="loading" v-model="serviceWantedForm.size" dense solo flat outlined
                                  class="rounded-lg input-label" height="40px" color="grey">
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row v-if="!active" class="mt-0">
                                <v-col class="mx-3 py-0">
                                  <v-card-text class="px-0 py-0 mb-1 text-label text-left">
                                      {{ i18n.t("frequency") }}
                                  </v-card-text>
                                  <v-select return-object :item-text="i18n.locale==='fr'?'frequencyFr':'frequency'" item-value="pickupFrequency"
                                    :items="resiServicesFrequency || pickupFrequencies" attach flat v-bind:rules="formRules.pickupFrequencyRules"
                                    :placeholder="i18n.t('iAmNotSure')" v-bind:disabled="loading"
                                    v-model="serviceWantedForm.frequency" dense solo outlined
                                    class="rounded-lg input-label" color="grey"></v-select>
                                </v-col>
                              </v-row>
                            </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                  <v-row class="mt-6">
                    <v-col class="py-0 mb-0">
                      <v-card-text class="px-0 py-0 text-left mb-1 text-label">
                        {{ i18n.t('startDate') }}
                      </v-card-text>
                      <v-menu v-model="form.wantedStartDateMenu" :close-on-content-click="false" offset-y
                        max-width="290px" min-width="auto" top attach="attach" v-bind:disabled="loading">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field readonly flat v-model="form.wantedStartDateFormatted" dense outlined
                            :placeholder="i18n.t('select')" :hint="canada ? 'YYYY-MM-DD' : 'MM/DD/YYYY'"
                            persistent-hint="persistent-hint"
                            v-bind:rules="canada ? formRules.dateCanadaRules : formRules.dateRules" solo
                            class="rounded-lg input-label" v-bind="attrs" v-on="on"
                            v-bind:disabled="loading" color="grey"></v-text-field>
                        </template>
                        <v-date-picker v-model="form.wantedStartDate" no-title="no-title" v-bind:min="form.minDate"
                        v-bind:max="form.maxDate"
                        v-bind:locale="!canada ? 'en-US' : lang == 'fr' ? 'fr-CA' : 'en-CA'"
                        v-on:input="form.wantedStartDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row v-if="!formValid" class="pt-5">
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2" >
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField') }}
            </div>
          </div>
        </v-row>

        <!-- Button back & continue -->
        <div class="mt-6">
          <!-- <v-btn :width="$vuetify.breakpoint.mobile ? '39%': button.back.width" class="btn-label rounded-lg white--text mr-2 flex-grow-0 accent lighten-1"  height="70px"  @click="loading ? null : back()">
            <v-icon x-large left>
              mdi-chevron-left
            </v-icon>
            <v-spacer></v-spacer>
            {{ i18n.t("back") }}
            <v-spacer></v-spacer>
          </v-btn>
          <v-btn :width="$vuetify.breakpoint.mobile ? '58%': button.continue.width" v-if="apiResult.storeFrontURL && getLeadTypeFromServiceType(form.serviceType, form.categoryType) == 'Residential'" :color="!formValid ? 'error' : 'success'" outlined height="70px" class="btn-label rounded-lg " :class=" !formValid ? 'red-outlined' : 'green-outlined'" :style="windowSize.x < 475 ? 'font-size: 14px !important' : ''" v-ripple="loadingWithService ? false : true" @click="loadingWithService ? null : validateWithService()">
            <v-spacer></v-spacer>
                    {{ loadingWithService ? i18n.t("validate") : i18n.t("goToStore") }}              <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
          <v-btn :width="$vuetify.breakpoint.mobile ? '58%': button.continue.width" v-else :color="!formValid ? 'error' : 'success'" height="70px" class="btn-label rounded-lg" v-ripple="loading ? false : true" @click="loading ? null : validate()">
            <v-spacer></v-spacer>
                    {{ loading ? i18n.t("validate") : i18n.t("getMyQuote") }}
          <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn> -->
          <ForwardBtn :parentRefs="$refs" currentTabComponent="DetailView"></ForwardBtn>
        </div>
        <!-- <div v-if="apiResult.storeFrontURL && getLeadTypeFromServiceType(form.serviceType, form.categoryType) !== 'Residential'" class="d-flex flex-row mt-6 justify-end">
          <v-btn :width="$vuetify.breakpoint.mobile ? '58%': button.continue.width" :block="windowSize.x < 475 ? true : false" :color="!formValid? 'error' : 'success'" outlined height="70px" class="btn-label rounded-lg" :class=" !formValid? 'red-outlined' : 'green-outlined'" v-ripple="loadingWithService ? false : true" @click="loadingWithService ? null : validateWithService()">
            <v-spacer></v-spacer>
              {{ loadingWithService ? i18n.t("validate") : i18n.t("goToStore") }}
            <v-spacer></v-spacer>
            <v-icon v-if="!loading" x-large right>
              mdi-chevron-right
            </v-icon>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
        </div> -->
      </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import NumberInput from "../../utility/NumberInput";
import icon from "../../utility/SvgLoader";
import { useIndexStore } from '../../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import ForwardBtn from "../../utility/forwardBtn.vue"
import BackBtn from "../../utility/backBtn.vue"

export default {
  name: "MhoamResidential",
  components:{NumberInput,icon,BackBtn,ForwardBtn},
  props: {
    canada: Boolean,
    terms: String,
    privacy: String,
    name: String,
    lang: String,
  },

  data() {
    return {
      i18n: i18n,
      hover:-1,
      servicesTypes: [
        {
          id: 1,
          name: "WasteDumpster",
          text: i18n.t('wasteDumpster'),
          icon: "solidwaste",
        },
        {
          id: 2,
          name: "RecyclingDumpster",
          text: i18n.t('servicesWantedForm.recyclingDumpster'),
          icon: "recyclingdumpstericon",
        },
      ],
    };
  },
  mounted() {
    if(this.showGoToStore){
      this.apartmentsCondosHoas = true;
    }
    this.residentialMhoamWantedForm.forEach(this.updateApartmentsCondosHoas);
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapState(useIndexStore, [
      "formRules",
      "showGoToStore",
      "containServicePackgesOf",
      "commServicesSizes",
      "resiServicesFrequency",
    ]),
    ...mapWritableState(useIndexStore, [
      "formErrors",
      "windowSize",
      "apiResult",
      "sizes",
      "pickupFrequencies",
      "validationPages",
      "formValid",
      "loading",
      'loadingWithService',
      'residentialMhoamWantedForm',
      'sizesWasteRecycling',
      "button",
      "apartmentsCondosHoas",
    ]),
    ...mapWritableState(useUserStore, [
      "form",
    ]),
    filteredSizesWasteRecycling() {
      return this.sizesWasteRecycling.filter(item => item.byDefault === true);
    }
  },
  watch: {
    'form.wantedStartDate': function () {
      if (this.form.wantedStartDate) {
        this.formatDate(this.form.wantedStartDate,this.canada ? "en_CA" : "","wantedStartDateFormatted");
      } else {
        this.form.wantedStartDateFormatted = null;
      }
    },
    'residentialMhoamWantedForm': {
      handler(newVal) {
        newVal.forEach(serviceWantedForm => {
          this.$watch(() => serviceWantedForm.size, (newSize, oldSize) => {
            if(newSize !== oldSize){
              this.updateApartmentsCondosHoas();
            }
          });
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions(useIndexStore, ['onResize','updateViewComponent','back','sendInfoWithService','formatDate','getLeadTypeFromServiceType','updateStages','filterExistOnly','clearCard','acceptNumber']),
    hoverEvent(id) {
      this.hover = id;
    },
    getValueFromInput(name, value, error) {
      if (name === "wantedQty") {
        this.form.wantedQty = value;
        this.form.wantedQtyErr = error;
      }
    },
    validateWithService() {
      this.loadingWithService = true;
      var vm = this
      if (vm.$refs.wasteForm.validate()) {
        vm.form.webLeadStep = "Details Stage";
        vm.sendInfoWithService()
      }else{
        vm.loadingWithService = false;
      }
    },
    validate() {
      this.loading = true;
      var vm = this;
      if (vm.$refs.wasteForm.validate()) {
        vm.updateStages("Details Stage");
      } else {
        vm.loading = false;
      }
    },
    updateApartmentsCondosHoas() {
      const isResidential = this.containServicePackgesOf('residential', true);
      const isCommercial = this.containServicePackgesOf('commercial', true);
      const isYard = this.residentialMhoamWantedForm.some(form => form.size?.binSize?.toLowerCase().includes('yard'));

      this.apartmentsCondosHoas = isYard ? isCommercial : this.showGoToStore;

      if (isResidential || (isYard && isCommercial)) {
        this.updateStages("Service Stage", false);
      }
    },
  },
};
</script>

<style scoped>
  .min-height-44 {
    min-height: 44px;
  }
  .box-shadow-custom {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  }
  ::v-deep .v-expansion-panel::before {
    box-shadow: none !important;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: initial;
  }
</style>
