<template>
  <v-container fluid>
  <v-main v-resize="onResize">
    <!-- <keep-alive> -->
    <v-card :outlined="!borderstore" :flat="borderstore">
      <!-- class="offset-lg1 lg10 offset-xl1 xl10" -->
      <v-flex>
        <componentWidget class="mb-3 mt-1"
                v-bind:is="currentTabComponent" :name="name"
                :service="service" :servicesofferedResi="servicesofferedResi" :servicesofferedComm="servicesofferedComm"
                :assigntodistrict="assigntodistrict" :phone="phone" :country="country" :canada="country==='canada'?true:false"
                :terms="terms" :privacy="privacy" :splitter="splitter" :lang="lang" :header="header" :primary="primary"
                :secondary="secondary"
                :serviceswantedwithpricing="serviceswantedwithpricing">
        </componentWidget>
      </v-flex>
    </v-card>
    <!-- <v-scroll-y-reverse-transition>
      <HandlingError class="ma-0" :errorAxios="errorAxios"></HandlingError>
    </v-scroll-y-reverse-transition> -->
    <v-card v-if="!footerstore" outlined>
      <v-alert class="grey lighten-3 ma-0" elevation ="3">
        <CustomerServiceBar class="py-0" :phone="phone"></CustomerServiceBar>
      </v-alert>
    </v-card>
    <v-dialog v-model="recaptchaDialog" attach hide-overlay max-width="290">
        <v-card>
            <v-card-title class="text-h5 d-flex justify-center">
                <v-icon x-large color="primary">mdi-alert-circle-outline</v-icon>
            </v-card-title>
            <v-card-text class="text-center">{{ i18n.t('snackbar.body1') }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="recaptchaDialog = false">
                    {{ i18n.t('snackbar.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-main>
  </v-container>

</template>

<script>
//Commercial Dumpster Chart
import ChooseDumpster from "../dumpstercommercialchartquote/ChooseDumpster";
import DumpsterCommercialForm from "../dumpstercommercialchartquote/DumpsterCommercialForm";
// Components pages
import ServiceView from "./view/ServiceView";
import ResidentialService from "./view/residential/ResidentialService";
import CommercialService from "./view/commercial/CommercialService";
import BulkPickUp from "./view/residential/BulkPickUp";
import MhoamView from "./view/commercial/MhoamView";
import MhoamResidential from "./view/residential/MhoamResidential";

import DumpsterRental from "./view/commercial/DumpsterRental";
import CompIndustWaste from "./view/commercial/CompIndustWaste";
import WasteRecyPickUp from "./view/commercial/WasteRecyPickUp";
import CommStorefront from "./view/commercial/CommercialStorefront";
import PortableToilets from "./view/commercial/PortableToilets";
import YardWaste from "./view/residential/YardWaste";
import ShreddingView from "./view/commercial/ShreddingView";
import ResiWastePickUp from "./view/residential/ResiWastePickUp";
import ResiStorefront from "./view/residential/ResidentialStorefront";
import FoodWaste from "./view/residential/FoodWaste";
import EWaste from "./view/residential/EWaste";

import OrganicWaste from "./view/residential/OrganicWaste";
import MunicipalitiesView from "./view/residential/MunicipalitiesView";
import ComBulkPickUp from "./view/commercial/ComBulkPickUp";
import SpecialWaste from "./view/commercial/SpecialWaste";
import ThankyouNote from "./view/thankyou/ThankyouNote";
import ThankyouConfirm from "./view/thankyou/ThankyouConfirm";
import ThankyouExplore from "./view/thankyou/ThankyouExplore";
import ProcessingOrder from "./view/thankyou/ProcessingOrder";

import NotFound from "./view/thankyou/NotFound";
import NumberInput from "./utility/NumberInput";


// Single components
import i18n from "@/i18n";
import CustomerServiceBar from "./footer/CustomerServiceBar";
import HandlingError from "./utility/HandlingError";
import { useUserStore } from "@/store/user";
import { useIndexStore } from "@/store/index";
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import googleApi from '../../plugins/google';
import salesforce from '../../plugins/salesforce';

export default {
  name: "MainView",
  props: {
    service: String,
    servicesofferedResi: String,
    servicesofferedComm: String,
    assigntodistrict: Number,
    storefrontDistrictCodeOverride: Number,
    lang: String,
    phone: String,
    country: String,
    terms: String,
    privacy: String,
    name: String,
    splitter: Boolean,
    header: String,
    primary: String,
    secondary: String,
    newstoreapi: Boolean,
    serviceswantedwithpricing: Boolean,
    footerstore: Boolean,
    headerstore: Boolean,
    borderstore: Boolean,
    serviceLobLocked: Boolean,
    resiServicesSizes: String,
    commServicesSizes: String,
    constServicesSizes: String,
    resiServicesFrequency: String,
    commServicesFrequency: String,
    resiServicesShowRecycling: Boolean,
    commServicesShowRecycling: Boolean,
  },

  components: {
    ServiceView, // Page #1
    ResidentialService, // Page #2
    CommercialService, // Page #3
    BulkPickUp, // Page #5
    MhoamView, // Page #7
    MhoamResidential,
    DumpsterRental, // Page #8 ----
    CompIndustWaste, // Page #10
    WasteRecyPickUp, // Page #11 ----
    CommStorefront,
    PortableToilets, // Page #14
    YardWaste, // Page #16
    ShreddingView, // Page #18
    ResiWastePickUp,
    ResiStorefront,
    OrganicWaste,
    MunicipalitiesView,
    ComBulkPickUp,
    SpecialWaste,
    ThankyouNote,
    ThankyouExplore,
    ThankyouConfirm,
    ProcessingOrder,
    NotFound,
    CustomerServiceBar,
    HandlingError,
    NumberInput,
    ChooseDumpster,
    DumpsterCommercialForm,
    FoodWaste,
    EWaste,
  },

  data: () => ({
    i18n: i18n,
  }),
  created() {
    i18n.locale = this.lang;
    this.assigntodistrictEdit = this.assigntodistrict;
    this.storefrontDistrictCodeOverrideEdit = this.storefrontDistrictCodeOverride;
    this.servicesofferedResiEdit = this.servicesofferedResi;
    this.servicesofferedCommEdit = this.servicesofferedComm;
    this.storeApiEdit = this.newstoreapi;
    this.headerstoreEdit = this.headerstore;
    this.serviceLobLockedEdit = this.serviceLobLocked;
    this.resiServicesShowRecyclingEdit = this.resiServicesShowRecycling;
    this.commServicesShowRecyclingEdit = this.commServicesShowRecycling;
    if(this.resiServicesSizes){
      this.resiServicesSizesEdit = this.filterObjectsBySize(this.sizesResiWasteRecycling, this.resiServicesSizes, true);
    }
    if(this.commServicesSizes){
      this.commServicesSizesEdit = this.filterObjectsBySize(this.sizesWasteRecycling, this.commServicesSizes, true);
    }
    if(this.constServicesSizes){
      this.constServicesSizesEdit = this.filterObjectsBySize(this.sizesROWasteRecycling, this.constServicesSizes, false);
    }
    if(this.resiServicesFrequency){
      this.resiServicesFrequencyEdit = this.filterObjectsByFrequency(this.pickupFrequencies, this.resiServicesFrequency, true);
    }
    if(this.commServicesFrequency){
      this.commServicesFrequencyEdit = this.filterObjectsByFrequency(this.pickupFrequencies, this.commServicesFrequency, true);
    }
    this.form.servicesWantedWithPricing = this.serviceswantedwithpricing;
  },
  mounted: function () {
    this.onResize();
    this.checkIfGTMLoadedThenSendLoadForm();
    salesforce.campaignStorageTracking();
    if (this.service === "residential") {
      this.form.serviceType = 'Residential';
    }
    if (this.service === "commercial" || this.service === "construction") {
      this.form.serviceType = 'Commercial';
    }
    if(this.primary){
      this.$vuetify.theme.themes.light.primary = this.primary;
    }
    if(this.secondary){
      this.$vuetify.theme.themes.light.secondary = this.secondary;
    }
  },
  computed: {
    ...mapState(useIndexStore, {
      formRules: 'formRules',
      hoas: 'hoas',
      sizesResiWasteRecycling: 'sizesResiWasteRecycling',
      sizesWasteRecycling: 'sizesWasteRecycling',
      sizesROWasteRecycling: 'sizesROWasteRecycling',
      pickupFrequencies: 'pickupFrequencies',
    }),
    ...mapWritableState(useIndexStore, {
      componentWidget: 'component',
      currentTabComponent: 'currentTabComponent',
      loading: 'loading',
      servicefromquote: 'servicefromquote',
      apiResult: 'apiResult',
      storefrontResult: 'storefrontResult',
      windowSize: 'windowSize',
      validationPages: 'validationPages',
      snackbar: 'snackbar',
      completeAddress: 'completeAddress',
      completeAddressMsg: 'completeAddressMsg',
      errorAxios: 'errorAxios',
      assigntodistrictEdit: 'assigntodistrict',
      storefrontDistrictCodeOverrideEdit: 'storefrontDistrictCodeOverride',
      servicesofferedResiEdit: 'servicesofferedResi',
      servicesofferedCommEdit: 'servicesofferedComm',
      storeApiEdit: 'newStoreApi',
      headerstoreEdit: 'headerstore',
      serviceLobLockedEdit: 'serviceLobLocked',
      resiServicesSizesEdit: 'resiServicesSizes',
      commServicesSizesEdit: 'commServicesSizes',
      constServicesSizesEdit: 'constServicesSizes',
      resiServicesFrequencyEdit: 'resiServicesFrequency',
      commServicesFrequencyEdit: 'commServicesFrequency',
      resiServicesShowRecyclingEdit: 'resiServicesShowRecycling',
      commServicesShowRecyclingEdit: 'commServicesShowRecycling',
      recaptchaDialog: 'recaptchaDialog',
      }),
    ...mapWritableState(useUserStore, [
          "form",
        ]),
  },
  watch: {
    "form.address": function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.completeAddressMsg = "";
      }
    },
    "form.street": function (val) {
      if (val !== undefined) {
        this.completeAddress = true;
        this.completeAddressMsg = "";
      } else {
        this.completeAddress = false;
        this.completeAddressMsg += i18n.t("streetNumberRequired");
      }
    },
    "form.zip": function (val) {
      if (val !== undefined) {
        this.completeAddress = true;
        this.completeAddressMsg = "";
      } else {
        this.completeAddress = false;
        this.completeAddressMsg += i18n.t("zipCodeRequired");
      }
    },
  },
  methods: {
    ...mapActions(useIndexStore, ['onResize','editSEO',
    'updateViewComponent',
    ]),
    checkIfGTMLoadedThenSendLoadForm() {
      if (window.google_tag_manager && typeof window.dataLayer) {
        googleApi.gTagSendEvents(window.parent.location.href, 0, "Load Form", process.env.VUE_APP_GTAG_LEAD_CATEGORY);
      }else {
        setTimeout(this.checkIfGTMLoadedThenSendLoadForm, 500);
      }
    },
    filterObjectsBySize(objects, sizesServices, addNotSure) {
      // Parse sizesServices once before the loop and convert to upper case
      const parsedSizesServices = JSON.parse(sizesServices.replace(/'/g, '"')).map(size => size.toUpperCase());

      // Use Array.filter() to create a new array with objects that match any of the sizes
      let filteredObjects = objects.filter(obj => parsedSizesServices.includes(obj.binSize.toUpperCase()));

      // If addNotSure is true and objects array is not empty, add the first object to the beginning of the array
      if (addNotSure === true && objects.length > 0) {
        filteredObjects.unshift(objects[0]);
      }

      return filteredObjects;
    },
    filterObjectsByFrequency(objects, frequencysServices, addNotSure) {
      // Parse frequencysServices once before the loop and convert to upper case
      const parsedfrequencysServices = JSON.parse(frequencysServices.replace(/'/g, '"')).map(frequency => frequency.toUpperCase());

      // Use Array.filter() to create a new array with objects that match any of the frequencys
      let filteredObjects = objects.filter(obj => parsedfrequencysServices.includes(obj.frequency.toUpperCase()));

      // If addNotSure is true and objects array is not empty, add the first object to the beginning of the array
      if (addNotSure === true && objects.length > 0) {
        filteredObjects.push(objects[objects.length - 1]);
      }

      return filteredObjects;
    }
   }
};

</script>

<style scoped>
/* CSS GLOBAL */

/* Main CSS modded */
/* prepend inner icon */
::v-deep .checkmark-solo {
  position: absolute;
  left:97%;

}
::v-deep .checkmark-right{
  position: absolute;
  right:5%;
  width: 1.5em;
  height: 1.5em;
  }
::v-deep .v-input__icon.v-input__icon--prepend-inner i {
  font-size: 28px !important;
  color: #1f528f;
}
::v-deep .v-list-item__title {
  white-space: normal !important;
}
::v-deep .placeholder-position input::placeholder {
  padding-left: 5px !important;
}
::v-deep .subtitle-2 {
  word-break: normal !important;
}
::v-deep .v-card-title {
  word-break: normal !important;
}

::v-deep .list-item-label {
  font-size: 23px !important;
  word-break: normal !important;
  color: black !important;
}
::v-deep .text-body-2 {
  font-size: 20px !important;
}
/* hover effect & selected item */
/* List */
::v-deep .theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
    opacity: 0 !important;
}
::v-deep .selected-list-icon :hover {
  position: relative;
  top: -15%;
}
::v-deep .residential-bg-selected {
  background-color: #175e45 !important;
}
::v-deep .residential-bg {
  background: #e9f4f0 !important;
}
::v-deep .commercial-bg {
  background-color: #f0f7fe !important;
}
::v-deep .commercial-bg-selected {
  background-color: #1f528f !important;
}
::v-deep .v-label {
  color: black !important;
}
::v-deep .green-lighten-5 {
  background-color: #d6e6e1 !important;
  opacity: 1 !important;
}
::v-deep .blue-lighten-5 {
  background-color: #f0f7fe !important;
  opacity: 1 !important;
}
::v-deep .blue-darken-4 {
  background-color: #153F70 !important;
}
::v-deep .rounded-xl-description {
  border-radius: 12px !important;
  height: 82px !important;
  margin-bottom: 20px !important ;
}
::v-deep button {
  text-transform: none !important;
}
::v-deep a {
  color: #175e45 !important;
  text-decoration: none;
}
::v-deep .text-label {
  font-size: 16px;
  line-height: 21px;
  word-break: normal !important;
  color: black !important;
}
::v-deep .text-title {
  font-size: 30px;
  font-weight: bold;
  word-break: normal !important;
  color: black !important;
}
::v-deep .btn-label {
  font-size: 20px !important;
  line-height: 25px;
  word-break: normal !important;
  justify-content: center; /* horizontally centered */
}
::v-deep .mid-text {
  justify-content: center !important;
  text-align: center !important;
}
::v-deep button span {
  justify-items: end;
}
::v-deep .input-label {
  font-size: 14px !important;
  word-break: normal !important;
  color: black !important;
  border-radius: 10px !important;
}
::v-deep .select-label {
  font-size: 18px;
  word-break: normal !important;
  color: black !important;
}
::v-deep .label-sqr-btn {
  font-size: 20px;
  font-weight: bold;
  word-break: normal !important;
  color: black !important;
  border-radius: 10px;
  padding-top: 36px !important ;

}
::v-deep .text-quote {
  font-size: 22px;
}
::v-deep .text-quote-ty {
  font-size: 20px;
}
::v-deep .congrats {
  font-size: 32px;
}
::v-deep .img-outter-div {
  position: relative;
  bottom: -40px;

}
::v-deep .icon-checked {
  position: relative;
  top: -55px;
  right: -50px;

}
::v-deep .selected-outter-scale {
  position: relative;
  top: -5px;
  right: -7px;
}
::v-deep .checkmark-wastesolution {
  z-index: 1;
  position: absolute;
  left: 94%;
  bottom: 35%;
}
::v-deep .active-icon {
  position: relative;
  left:22%;
}
::v-deep .checkmark-compactors {
  position: absolute;
  left: 80%;
  bottom: 60%;
}
::v-deep .checkmark-list {
  position: absolute;
  left: 98%;
}
::v-deep .static{
  position: static;
}
::v-deep .checkmark-list-more {
  position: absolute;
  left: 94%;
}
::v-deep .selected-residential {
  position: relative;
  top: -1px;
  right: -8px;
}
::v-deep .selected-borg {
  position: relative;
  top: -3px;
  right: -2px;
}
::v-deep .btn-inc-dec {
  width: 20px;
  height: 35px;
}


/* prepend inner icon */
::v-deep .pholder-position input {
  padding-left: 5px !important;
}
/* Screen resizing */
::v-deep .mobile-my-20{
  margin: 40px 0px 40px 0px;
}
::v-deep .mobile-my-10{
   margin: 20px 0px 20px 0px;
}


@media screen and (min-width: 1920px) {

  ::v-deep .label-sqr-btn {
    padding-top: 12px !important ;
  }


}

@media screen and (max-width: 1920px) {

  ::v-deep .label-sqr-btn {
    padding-top: 12px !important ;
  }
  ::v-deep .checkmark-list-more {
    position: absolute;
    left: 96%;
  }

}
@media screen and (max-width: 1264px) {

::v-deep .label-sqr-btn {
  padding-top: 12px !important ;
  font-size: 20px !important;
  }

}

@media screen and (max-width: 960px) {



  ::v-deep .service-bar-span{
    font-size:18px !important ;
  }
  /* ResiWastePickup */
  ::v-deep .mobile-my-20{
   margin: 20px 0 20px 0;
}
  /* Mhoam */
  ::v-deep .mobile-my-10{
   margin: 0px 0px 0px 0px;
}
  ::v-deep .text-title {
  font-size: 24px !important;

}
}
@media screen and (max-width: 600px) {
::v-deep .checkmark-list-more {
  position: absolute;
  left: 92%;
}
  ::v-deep .checkmark-list-mobile{
  position: absolute;
  left: 92%;
}
/* 15px font after */
  ::v-deep .list-item-label {
    font-size: 14px !important;
  }
  ::v-deep .text-title {
    font-size: 26px;
    text-align: center;
  }
  ::v-deep .label-sqr-btn {
    font-size: 18px !important;
  }
  ::v-deep .service-bar-span{
    font-size:18px !important ;
  }
  ::v-deep .checkmark-wastesolution {
    position: absolute;
    left: 86%;
    bottom: 44%;
  }
  ::v-deep .card-label {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 475px)  {

  ::v-deep .label-sqr-btn {
    padding-top: 12px !important ;
    font-size: 16px !important;

  }
  ::v-deep .service-bar-span{
    font-size:16px !important ;
  }
  ::v-deep .v-list-item__title.text-h6{
    font-size:16px !important;
  }
  ::v-deep .text-quote {
    font-size: 16px;
  }
  ::v-deep .text-quote-ty {
    font-size: 16px;
  }
  ::v-deep .congrats {
    font-size: 28px;
  }

}
@media screen and (max-width: 375px)  {

  ::v-deep .label-sqr-btn {
    font-size: 14px!important;

    padding-top: 10px !important ;
  }

  ::v-deep .btn-label{
    font-size: 16px !important;
  }


}
@media screen and (max-width: 280px) {

  ::v-deep .label-sqr-btn {
    font-size: 12px !important;
    padding-top: 14px !important ;
  }

  ::v-deep .service-bar-span{
    font-size:16px !important ;
  }

}

::v-deep .service-bar-btn {
  border-radius: 7px !important;
  background-color: white !important;
  border-color: #003F6F !important;
  border-style: solid !important;
}
::v-deep .service-bar-span {
  word-break: normal !important;
  font-size: 20px ;
  color: #003F6F !important;
}
::v-deep .service-bar-btn-phone {
  background-color: #003F6F !important;
  border-radius: 7px;
  padding: 0px !important;
  min-width: 46px !important;
}
::v-deep tr:hover {
        background-color: transparent !important;
     }
::v-deep .cursor-pointer{
  cursor: pointer;
}
/*  SVG COLOR CSS */
::v-deep .green-svg {
  fill: #175e45 !important;
}
::v-deep .white-svg {
  fill: white !important;
}
::v-deep .blue-svg {
  fill: #1f528f !important;
}
::v-deep .blue-hover-fill-svg {
  fill: #153f70 !important;
}
::v-deep .green-hover-fill-svg {
  fill: #153f70 !important;
}
::v-deep .inactive-fill-svg {
  fill: #B2B2B2 !important;
}
::v-deep .inactive-svg {
  background-color: #b2b2b2 !important;
  color:white !important;
}

::v-deep .blue-default-svg {
  background-color: #F0F7FE !important;
}
::v-deep .blue-active-svg {
  background-color: #1F528F !important;
}
::v-deep .blue-hover-svg {
  background-color: #DAE7F5 !important;
  color:black !important;

}
::v-deep .green-default-svg {
  background-color: #E9F4F0 !important;
}
::v-deep .green-active-svg {
  background-color: #175E45 !important;
}
::v-deep .green-hover-svg {
  background-color: #DCEAE5 !important;
  color:black !important;
}

::v-deep .green-outlined {
  border-color:#148432 !important;
  color:#148432 !important;
}

/* for number input */
::v-deep .red-outlined {
  border-color: red !important;
  color: red !important;
}


/* for v-card */
::v-deep .red-border {
  border-width:2px !important;
  border-color:red !important;
}

::v-deep .btn-explore {
  background-color: #153F70 !important;
  color:white !important;
}
::v-deep .circle {

  height: 1.5em;
  width: 1.5em;
  border-radius: 100%;
  display: inline-block;
  outline: #bbb solid 2px;

}
::v-deep .v-carousel__controls__item{
  margin-top:20px!important;
  color: grey !important
}
::v-deep .v-carousel__controls__item.v-btn.v-btn--active {
  color: green !important;
}
</style>
